import { useMemo } from "react";
import { SocketIO } from "../../redux/gameService";
import { toggleManualCashOut } from "../../redux/gameSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useTranslate } from "../../utils/hooks";
import {
  CashOutButton,
  CashOutTitle,
  CashOutValue,
  StyledManualCashOut,
} from "./styles";

const ManualCashOut: React.FC = () => {
  const dispatch = useAppDispatch();
  const bet = useAppSelector((state) => state.game.bet);
  const tick = useAppSelector((state) => state.game.tick) || 0;
  const finalRate = useAppSelector((state) => state.game.finalRate) || 0;
  const gameStatus = useAppSelector((state) => state.game.gameStatus);
  const manualCashOut = useAppSelector((state) => state.game.manualCashOut);
  const translate = useTranslate();

  const isAutoCashOutDisabled =
    gameStatus === "finish" || Boolean(manualCashOut.isTakenAutoCashOut);

  const handleClick = (type: "half_auto_rate" | "auto_rate") => {
    SocketIO.takeBet(type);

    const key =
      type === "half_auto_rate"
        ? "isTakenHalfAutoCashOut"
        : "isTakenAutoCashOut";

    dispatch(toggleManualCashOut({ key, value: true }));
  };

  const autoCashOut = useMemo(() => {
    if (manualCashOut.isTakenHalfAutoCashOut) {
      const half = +bet / 2;

      return parseFloat((half * (tick || finalRate)).toFixed(2));
    }

    return parseFloat((+bet * (tick || finalRate)).toFixed(2));
  }, [bet, tick, manualCashOut.isTakenHalfAutoCashOut]);

  return (
    <StyledManualCashOut>
      <CashOutButton
        borderRadius="14px 0px 0px 14px"
        disabled={isAutoCashOutDisabled}
        onClick={() => handleClick("auto_rate")}
      >
        <CashOutTitle>{translate("CASHOUT")}</CashOutTitle>

        <CashOutValue>{autoCashOut}</CashOutValue>
      </CashOutButton>

      <CashOutButton
        borderRadius="0px 14px 14px 0px"
        disabled={
          isAutoCashOutDisabled || Boolean(manualCashOut.isTakenHalfAutoCashOut)
        }
        onClick={() => handleClick("half_auto_rate")}
      >
        <CashOutTitle>{translate("CASHOUT")} 50%</CashOutTitle>

        <CashOutValue>
          {parseFloat(((+bet / 2) * (tick || finalRate)).toFixed(2))}
        </CashOutValue>
      </CashOutButton>
    </StyledManualCashOut>
  );
};

export default ManualCashOut;
