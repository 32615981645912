import { ReactComponent as ArrowLeft } from "../../assets/chevronLeft.svg";
import { ReactComponent as ArrowRight } from "../../assets/chevronRight.svg";
import { useOutsideClick } from "../../utils/hooks";
import { StyledArrowControls, ArrowWrapper } from "./styles";

type ArrowControlsProps = {
  value: string | number;
  handleDecrease: () => void;
  handleIncrease: () => void;
  handleChange: (value: string) => void;
  handleBlur?: (value: string) => void;
  suffix?: string;
  digitsAfterDote?: number;
};

const ArrowControls: React.FC<ArrowControlsProps> = ({
  value,
  handleDecrease,
  handleIncrease,
  handleChange,
  handleBlur,
  digitsAfterDote,
  suffix = " $",
}) => {
  const { ref, isVisible, setIsVisible } = useOutsideClick(false);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = event.target;

    if (+value > 999.99 || +value < 0) return;

    const [_, hundredths] = value.split(".");

    if (hundredths && hundredths.length > 2) return;

    handleChange(value);
  };

  const onBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = event.target;

    handleBlur && handleBlur(value);
  };

  return (
    <StyledArrowControls suffix={suffix} ref={ref}>
      <ArrowWrapper>
        <ArrowLeft
          width={20}
          height={20}
          onClick={handleDecrease}
          cursor="pointer"
        />
      </ArrowWrapper>

      {!isVisible ? (
        <span onClick={() => setIsVisible(true)}>{value}</span>
      ) : (
        <input
          autoFocus
          type="text"
          value={value}
          onChange={onChange}
          onBlur={onBlur}
        />
      )}

      <ArrowWrapper>
        <ArrowRight
          width={20}
          height={20}
          onClick={handleIncrease}
          cursor="pointer"
        />
      </ArrowWrapper>
    </StyledArrowControls>
  );
};

export default ArrowControls;
