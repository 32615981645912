import depositModalBackground from "../../assets/depositModalBackground.png";
import historyRangeBackground from "../../assets/historyRangeBackground.png";
import styled from "styled-components";

export const StyledStatistics = styled.div({
  position: "absolute",
  background: `url(${depositModalBackground})`,
  backgroundSize: "cover",
  top: -275,
  left: "auto",
  width: "300px",
  height: "270px",
  padding: "10px 15px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",

  "@media screen and (min-width: 950px)": {
    left: 0,
  },
});

export const Title = styled.span({
  fontSize: "18px",
  lineHeight: "24px",
  fontWeight: 600,
  textAlign: "center",
  textTransform: "uppercase",
  color: "white",
  marginTop: "15px",
});

export const HistoryWrapper = styled.div({
  width: "100%",
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  gap: "3px",
  height: "150px",
  overflow: "scroll",
});

export const RateItem = styled.div<{ background: string }>(
  ({ background }) => ({
    width: "23%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: `url(${background})`,
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    height: "30px",
    color: "white",
  })
);

export const CountRange = styled.div({
  background: `url(${historyRangeBackground})`,
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  height: "36px",
  width: "190px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "20px",
  fontWeight: 600,
  color: "#92ACC9",
  gap: "7px",
  padding: "0px 10px",

  "& svg": {
    minWidth: 20,
    minHeight: 15,
  },

  "& input": {
    width: "60%",

    "&[type=range]::-webkit-slider-runnable-track": {
      "-webkit-appearance": "none",
      background:
        "linear-gradient(270deg, rgba(255, 252, 221, 0.65) 2.26%, rgba(193, 199, 187, 0.53) 100%)",
      height: "5px",
      borderRadius: "5px",
    },
    "&[type=range]::-webkit-slider-thumb": {
      "-webkit-appearance": "none",
      width: "10px",
      height: "10px",
      marginTop: -5,
      background: "purple",
      cursor: "pointer",
    },
  },
});
