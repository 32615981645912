import styled from "styled-components";
import arrowIconBackground from "../../assets/arrowIconBackground.png";

export const StyledArrowControls = styled.div<{ suffix: string }>(
  ({ suffix }) => ({
    display: "grid",
    gridTemplateColumns: "20px 60px 20px",
    columnGap: "12px",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
    fontWeight: 900,
    fontSize: "16px",
    lineHeight: "28px",
    fontFamily: "Stolzl",

    "& span": {
      textAlign: "center",

      "&::after": {
        content: `'${suffix}'`,
      },
    },

    "& input": {
      fontWeight: 900,
      fontSize: "16px",
      lineHeight: "28px",
      fontFamily: "Stolzl",
      background: "transparent",
      outline: "none",
      border: "none",
      color: "white",
      textAlign: "center",
    },

    "@media screen and (max-width: 950px)": {
      gridTemplateColumns: "20px 100px 20px",

      "& span": {
        fontSize: "25px",
      },

      "& input": {
        fontSize: "25px",
      },
    },
  })
);

export const ArrowWrapper = styled.div({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "20px",
  height: "20px",

  "@media screen and (max-width: 950px)": {
    width: "30px",
    height: "30px",
    background: `url(${arrowIconBackground})`,
  },
});
