export const RU = {
  CANCEL: "Отмена",
  CONFIRM_BET: "Сделать ставку",
  BET: "Ставка",
  AUTO_CASHOUT: "Авто.вывод",
  USER: "Игрок",
  MULTIPLIER: "Множитель",
  WIN: "Выигрыш",
  POSSIBLE_GAIN: "Возможний выграш",
  YOU_WIN: "Ваш выграш",
  YOUR_BET: "Ваша ставка",
  CASHOUT: "Вывод",
  NEXT_GAME_IN: "Игра начнеться через",
  CRASHED: "РАЗБИЛСЯ",
  FINAL_RATE: "Окончательная ставка",
  TIME: "Время",
  ONLINE_USERS: "Игроки в сети",
  PLAYING_USERS: "Игроки сделавшие ставку",
  BALANCE: "Баланс",
  INTRODUCTION: "Введение",
  HOW_TO_PLAY: "Как играть",
  PLACING_BET: "Размещение ставок",
  CHASHING_OUT: "Обналичивание",
  AUTOPLAY: "Автозапуск",
  RETURN_TO_PLAYER: "Возврат к игроку",
  LAST_RESULTS: "Последние результаты",
  LEADERBOARD: "Таблица лидеров",
  STATISTICS: "Статистика",
  NAVIGATION_CONTROLS: "Элементы управления навигацией",
  GAME_INCONVENIENCES: "Игровые неудобства",
  DISCONNECTION_POLICY: "Политика отключения",
  GAME_HELP: "Справка по игре",
  LOW_BALANCE: "Для совершения ставки необходимо пополнить счет",
  DEPOSIT: "Пополнить",
};
