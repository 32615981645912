import styled from "styled-components";
import mobileArrowBackground from "../../../assets/mobileArrowBackground.png";
import mobileCheckBackground from "../../../assets/mobileCheckBackground.png";
import activeMobileCheckBackground from "../../../assets/activeMobileCheckBackground.png";

export const StyledArrowControl = styled.div({
  width: "350px",
  display: "flex",
  gap: "5px",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  padding: "0px 0px 20px",
});

export const ControlWrapper = styled.div({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

export const ConfirmButton = styled.button<{ isActive: boolean }>(
  ({ isActive }) => ({
    width: "88px",
    height: "82px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    border: "none",
    background: `url(${
      isActive ? activeMobileCheckBackground : mobileCheckBackground
    })`,

    "& svg path": {
      fill: isActive ? "#C3FFCD" : "#70D580",
    },
  })
);

export const ArrowWrapper = styled.div<{ isDisabled: boolean }>(
  ({ isDisabled }) => ({
    width: "258px",
    height: "85px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    background: `url(${mobileArrowBackground})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    padding: "20px",
    pointerEvents: isDisabled ? "none" : "initial",
  })
);

export const ControlTitle = styled.div({
  fontSize: "16px",
  fontWeight: 900,
  lineHeight: "16px",
  color: "white",
});
