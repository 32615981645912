import styled from "styled-components";
import mobileBetBackground from "../../../assets/mobileBetBackground.png";

export const StyledBetControl = styled.div({
  width: "350px",
  height: "180px",
  background: `url(${mobileBetBackground})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  padding: "20px",
});

export const BetWrapper = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  fontSize: "25px",
  fontWeight: 900,
  color: "white",
  padding: "0px 20px",
});

export const ButtonWrapper = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "5px",
  marginTop: "20px",
});

export const Actions = styled.div({
  display: "flex",
  marginTop: "15px",
  gap: "15px",
  justifyContent: "space-between",
  alignItems: "center",
});

export const ActionsWrapper = styled.div({
  display: "flex",
  gap: "5px",
});
