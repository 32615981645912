import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type initialStateProps = {
  userData: any;
  isLoggedIn: boolean;
};

const initialState: initialStateProps = {
  userData: null,
  isLoggedIn: true,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.userData = action.payload;
      state.isLoggedIn = true;
    },
  },
});

export const { setUserData } = userSlice.actions;

export default userSlice.reducer;
