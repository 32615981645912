import styled from "styled-components";
import background from "../../assets/buttonBackground.png";

export const StyledGame = styled.div({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
  alignItems: "center",
  paddingBottom: "25px",
  position: "relative",

  "@media screen and (max-width: 950px)": {
    height: "auto",
    marginTop: "20px",
  },
});

export const WinBadge = styled.div<{
  isVisible: boolean;
  position?: "top" | "bottom";
}>(({ isVisible, position = "bottom" }) => ({
  background: `url(${background})`,
  backgroundSize: "contain",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  width: "300px",
  height: "58px",
  zIndex: isVisible ? 3 : 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "white",
  position: "absolute",
  ...(position === "bottom" ? { bottom: 0 } : { top: -55 }),
  fontWeight: 600,
  opacity: isVisible ? 1 : 0,
  transition: "opacity .5s linear",
}));
