export const EN = {
  CANCEL: "Cancel",
  CONFIRM_BET: "Confirm bet",
  BET: "Bet",
  AUTO_CASHOUT: "Auto Cashout",
  USER: "User",
  MULTIPLIER: "Multiplier",
  WIN: "Win",
  POSSIBLE_GAIN: "Possible gain",
  YOU_WIN: "You win",
  YOUR_BET: "Your bet",
  CASHOUT: "Cashout",
  NEXT_GAME_IN: "NEXT GAME IN",
  CRASHED: "CRASHED",
  FINAL_RATE: "Final rate",
  TIME: "Time",
  ONLINE_USERS: "Online users",
  PLAYING_USERS: "Playing users",
  BALANCE: "Balance",
  INTRODUCTION: "Introduction",
  HOW_TO_PLAY: "How to play",
  PLACING_BET: "Placing bet",
  CHASHING_OUT: "Chashing out",
  AUTOPLAY: "Autoplay",
  RETURN_TO_PLAYER: "Return to player",
  LAST_RESULTS: "Last results",
  LEADERBOARD: "Leaderboard",
  STATISTICS: "Statistics",
  NAVIGATION_CONTROLS: "Navigation controls",
  GAME_INCONVENIENCES: "Game inconveniences",
  DISCONNECTION_POLICY: "Disconnection policy",
  GAME_HELP: "Game help",
  LOW_BALANCE: "To make a bet you need to top up your account",
  DEPOSIT: "Deposit",
};
