import { memo, useEffect, useState } from "react";
import { SocketIO } from "../../redux/gameService";
import { useAppSelector } from "../../redux/hooks";
import { useTranslate } from "../../utils/hooks";
import { PendingView, ProcessView, FinishView, PrizeView } from "./components";
import { StyledGame, WinBadge } from "./styles";

const Game = () => {
  const [isVisible, setVisibility] = useState(false);
  const [betIsVisible, setBetVisibility] = useState(false);
  const gameStatus = useAppSelector((state) => state.game.gameStatus);
  const bet = useAppSelector((state) => state.game.bet);
  const tick = useAppSelector((state) => state.game.tick);
  const betConfirmed = useAppSelector((state) => state.game.betConfirmed);
  const prize = useAppSelector((state) => state.game.manualCashOut.prize);
  const isTakenAutoCashOut = useAppSelector(
    (state) => state.game.manualCashOut.isTakenAutoCashOut
  );
  const isTakenHalfAutoCashOut = useAppSelector(
    (state) => state.game.manualCashOut.isTakenHalfAutoCashOut
  );
  const translate = useTranslate();

  useEffect(() => {
    if (prize && Boolean(isTakenAutoCashOut || isTakenHalfAutoCashOut)) {
      setVisibility(true);

      setTimeout(() => {
        setVisibility(false);
      }, 2500);
    }
  }, [isTakenAutoCashOut, isTakenHalfAutoCashOut, prize]);

  useEffect(() => {
    if (betConfirmed) {
      setBetVisibility(true);

      setTimeout(() => {
        setBetVisibility(false);
      }, 2500);
    }
  }, [betConfirmed]);

  useEffect(() => {
    const token =
      new URLSearchParams(window.location.search).get("token")?.split(" ")[1] ||
      "";

    if (token) {
      SocketIO.init(token);
    }

    if (!token) {
      SocketIO.destroy();
    }
  }, [SocketIO]);

  const getDigits = (prizeAmount: number) => {
    const [integer, decimal] = String(prizeAmount).split(".");

    if (decimal) {
      return `${integer}.${decimal.slice(0, 2)}`;
    }

    return `${integer}.00`;
  };

  return (
    <StyledGame>
      <WinBadge
        position="top"
        isVisible={gameStatus === "process" && betConfirmed}
      >
        {translate("POSSIBLE_GAIN")}: ${" "}
        {parseFloat(Number(Number(tick) * +bet).toFixed(2))}
      </WinBadge>

      {(gameStatus === "pending" || gameStatus === "start") && <PendingView />}
      {gameStatus === "process" && <ProcessView />}
      {gameStatus === "finish" && <FinishView />}
      {gameStatus === "prize" && <PrizeView />}

      <WinBadge isVisible={isVisible}>
        {prize &&
          (isTakenAutoCashOut || isTakenHalfAutoCashOut) &&
          `${translate("YOU_WIN")}: $ ${getDigits(prize)}
        `}
      </WinBadge>

      <WinBadge isVisible={betIsVisible}>
        {bet && betConfirmed && `${translate("YOUR_BET")}: $ ${bet}`}
      </WinBadge>
    </StyledGame>
  );
};

export default memo(Game);
