import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_USER_URL,
    prepareHeaders: (headers) => {
      const token = window.location.search.split("=")[1];

      headers.set("Authorization", token as string);

      return headers;
    },
  }),
  endpoints: (builder) => ({
    authMe: builder.mutation<any, null>({
      query: () => ({
        url: `/auth/me`,
        method: "POST",
      }),
    }),
  }),
});

export const { useAuthMeMutation } = userApi;
