import { ToolkitStore } from "@reduxjs/toolkit/dist/configureStore";
import { connect, Socket } from "socket.io-client";
import { gameStore } from "./store";
import {
  setTick,
  setGameStatus,
  setFinalRate,
  setTimeLeft,
  confirmBet,
  setRateHistory,
  setBalance,
  setBets,
  toggleManualCashOut,
} from "./gameSlice";

class SocketService {
  private store: ToolkitStore;
  private webSocket?: Socket;
  private gameStatusTimer?: NodeJS.Timer;

  constructor(store: ToolkitStore) {
    this.store = store;
  }

  async init(token: string) {
    this.webSocket = await connect(process.env.REACT_APP_BASE_GAME_URL || "", {
      transports: ["websocket"],
      secure: true,
      query: {
        token,
      },
    });

    this.webSocket?.on("connect", () => {
      console.log("WebSocket connected");

      this.initHandlers();

      this.getGameStatus();

      this.startGame();
    });
  }

  initHandlers() {
    this.webSocket?.on("bet:take", (data) => {
      const { balance } = this.store.getState().game;

      this.store.dispatch(
        toggleManualCashOut({
          key: "prize",
          value: data.data.balance - balance,
        })
      );

      this.webSocket?.emit("game:balance");
    });

    this.webSocket?.on("bet:result", (data) => {
      this.store.dispatch(setBets(data.data));
    });

    this.webSocket?.on("bet:make", () => {
      this.webSocket?.emit("game:balance");
    });

    this.webSocket?.on("bet:cancel", () => {
      this.webSocket?.emit("game:balance");
    });

    this.webSocket?.on("game:balance", (data) => {
      this.store.dispatch(setBalance(data.data.balance));
    });

    this.webSocket?.on("game:history", (data) => {
      this.store.dispatch(setRateHistory(data.data));
    });

    this.webSocket?.on("game:tick", (data) => {
      const {
        bet,
        betConfirmed,
        gameStatus,
        autoCashOut,
        halfAutoCashOut,
        manualCashOut: { isTakenAutoCashOut, isTakenHalfAutoCashOut },
      } = this.store.getState().game;

      const isAutoCashOutActive = betConfirmed && autoCashOut.active;
      const isHalfAutoCashOutActive = betConfirmed && halfAutoCashOut.active;
      const autoCashOutNumber = Number(autoCashOut.stopLimit).toFixed(2);
      const halfAutoCashOutNumber = Number(halfAutoCashOut.stopLimit).toFixed(
        2
      );

      if (gameStatus !== "process") {
        this.store.dispatch(setGameStatus("process"));

        clearInterval(this.gameStatusTimer);
      }

      if (
        !isTakenAutoCashOut &&
        isAutoCashOutActive &&
        autoCashOutNumber <= data.rate
      ) {
        this.store.dispatch(
          toggleManualCashOut({
            key: "isTakenAutoCashOut",
            value: true,
          })
        );
        this.store.dispatch(
          toggleManualCashOut({
            key: "prize",
            value: isTakenHalfAutoCashOut
              ? +bet * (+autoCashOutNumber / 2)
              : +bet * +autoCashOutNumber,
          })
        );

        this.webSocket?.emit("game:balance");

        window.localStorage.removeItem("autoCashOut");
      }

      if (
        !isTakenHalfAutoCashOut &&
        !isTakenAutoCashOut &&
        isHalfAutoCashOutActive &&
        halfAutoCashOutNumber <= data.rate
      ) {
        this.store.dispatch(
          toggleManualCashOut({
            key: "isTakenHalfAutoCashOut",
            value: true,
          })
        );
        this.store.dispatch(
          toggleManualCashOut({
            key: "prize",
            value: +bet * (+halfAutoCashOutNumber / 2),
          })
        );

        this.webSocket?.emit("game:balance");

        window.localStorage.removeItem("halfAutoCashOut");
      }

      this.store.dispatch(setTick(data.rate));
    });

    this.webSocket?.on("game:status", (data) => {
      if (data.data?.timeleft) {
        const seconds = Math.round(data.data.timeleft / 1000);

        if (seconds >= 0) {
          const formattedSeconds = seconds === 0 ? 0 : seconds;

          if (formattedSeconds === 1) {
            this.store.dispatch(setGameStatus("start"));
          }

          this.store.dispatch(setTimeLeft(formattedSeconds));
        }
      }

      if (data.data?.bets) {
        this.store.dispatch(setBets(data.data.bets));
      }
    });

    this.webSocket?.on("game:final", (data) => {
      this.store.dispatch(setFinalRate(data.data.rate));

      this.store.dispatch(setGameStatus("finish"));

      this.webSocket?.emit("game:history");

      this.webSocket?.emit("game:balance");
    });

    this.webSocket?.on("game:new", () => {
      this.resetGameStore();
    });
  }

  resetGameStore() {
    this.store.dispatch(setTick(null));
    this.store.dispatch(setTimeLeft("15"));
    this.store.dispatch(confirmBet(false));
    this.store.dispatch(setFinalRate(null));
    this.store.dispatch(setGameStatus("pending"));
    this.store.dispatch(
      toggleManualCashOut({ key: "isTakenAutoCashOut", value: null })
    );
    this.store.dispatch(
      toggleManualCashOut({ key: "isTakenHalfAutoCashOut", value: null })
    );
    this.store.dispatch(toggleManualCashOut({ key: "prize", value: 0 }));

    this.clearLocalStorage();

    this.getGameStatus();
  }

  startGame() {
    this.webSocket?.emit("game:start");

    this.webSocket?.emit("game:history");

    this.webSocket?.emit("game:balance");
  }

  getGameStatus() {
    this.gameStatusTimer = setInterval(() => {
      this.webSocket?.emit("game:status");
    }, 1000);
  }

  betConfirm() {
    const {
      bet: betAmount,
      autoCashOut,
      halfAutoCashOut,
    } = this.store.getState().game;

    let bet: Record<string, number> = {
      amount: +betAmount,
      rate_auto: 0,
      half_rate_auto: 0,
    };

    if (autoCashOut.active) {
      window.localStorage.setItem(
        "autoCashOut",
        JSON.stringify({ active: true, stopLimit: autoCashOut.stopLimit })
      );

      bet.rate_auto = +autoCashOut.stopLimit;
    }

    if (halfAutoCashOut.active) {
      window.localStorage.setItem(
        "halfAutoCashOut",
        JSON.stringify({ active: true, stopLimit: halfAutoCashOut.stopLimit })
      );

      bet.half_rate_auto = +halfAutoCashOut.stopLimit;
    }

    this.webSocket?.emit("bet:make", bet);

    window.localStorage.setItem("isBetMade", "true");
    window.localStorage.setItem("bet", `${betAmount}`);
  }

  cancelBet() {
    this.webSocket?.emit("bet:cancel");

    this.clearLocalStorage();
  }

  takeBet(type: "half_auto_rate" | "auto_rate") {
    this.webSocket?.emit("bet:take", {
      type,
    });

    this.clearLocalStorage();

    clearInterval(this.gameStatusTimer);
  }

  clearLocalStorage() {
    window.localStorage.removeItem("isBetMade");
    window.localStorage.removeItem("bet");
    window.localStorage.removeItem("autoCashOut");
    window.localStorage.removeItem("halfAutoCashOut");
  }

  socketClose() {
    this.webSocket?.close();
    clearInterval(this.gameStatusTimer);
  }

  destroy() {
    this.webSocket?.close();
  }
}

export const SocketIO = new SocketService(gameStore);
