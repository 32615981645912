import { memo, useEffect, useState } from "react";
import { OverlayTrigger } from "react-bootstrap";
import {
  ActionsWrapper,
  StyledHeader,
  StyledTooltip,
  UsersInfo,
} from "./styles";
// import { ReactComponent as ChatIcon } from "../../assets/chatIcon.svg";
// import { ReactComponent as HeadsetIcon } from "../../assets/headsetIcon.svg";
import { ReactComponent as InfoIcon } from "../../assets/infoIcon.svg";
// import { ReactComponent as VolumeIcon } from "../../assets/volumeIcon.svg";
// import { ReactComponent as SettingsIcon } from "../../assets/settingsIcon.svg";
// import { ReactComponent as VideoIcon } from "../../assets/videoIcon.svg";
import { ReactComponent as FullScreenIcon } from "../../assets/fullScreenIcon.svg";
import { ReactComponent as ClockIcon } from "../../assets/clockIcon.svg";
import { ReactComponent as PersonIcon } from "../../assets/personIcon.svg";
import IconHolder from "../IconHolder";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { toggleFAQModal } from "../../redux/gameSlice";
import { useTranslate } from "../../utils/hooks";

const icons = [
  // ChatIcon,
  // HeadsetIcon,
  InfoIcon,
  // VolumeIcon,
  // SettingsIcon,
  // VideoIcon,
];

const Header = () => {
  const balance = useAppSelector((state) => state.game.balance);
  const bets = useAppSelector((state) => state.game.bets);
  const isFAQOpen = useAppSelector((state) => state.game.isFAQOpen);
  const dispatch = useAppDispatch();
  const [time, setTime] = useState<null | string>("--:--");
  const translate = useTranslate();

  useEffect(() => {
    setInterval(() => {
      const date = new Date();
      const hours = date.getHours();
      const minutes = date.getMinutes();

      setTime(`${hours}:${minutes < 10 ? `0${minutes}` : minutes}`);
    }, 5000);
  }, [setTime]);

  const handleFullScreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen();
    }
  };

  const handleIconClick = (key: string) => {
    if (key === "info") {
      dispatch(toggleFAQModal(!isFAQOpen));
    }
  };

  return (
    <StyledHeader>
      <UsersInfo>
        <OverlayTrigger
          placement="bottom"
          overlay={<StyledTooltip>{translate("TIME")}</StyledTooltip>}
        >
          <div className="mobileSide" style={{ cursor: "default" }}>
            <ClockIcon width={12} height={12} /> {` ${time}`}
          </div>
        </OverlayTrigger>

        <OverlayTrigger
          placement="bottom"
          overlay={<StyledTooltip>{translate("PLAYING_USERS")}</StyledTooltip>}
        >
          <div className="mobileSide" style={{ cursor: "default" }}>
            <PersonIcon width={15} height={15} /> {bets.length}
          </div>
        </OverlayTrigger>

        <OverlayTrigger
          placement="bottom"
          overlay={<StyledTooltip>{translate("BALANCE")}</StyledTooltip>}
        >
          <div style={{ cursor: "default" }}>
            <span className="balance">{translate("BALANCE")}:</span> $
            {balance?.toFixed(2)}
          </div>
        </OverlayTrigger>
      </UsersInfo>

      <ActionsWrapper>
        {icons.map((Icon, index) => (
          <IconHolder
            onClick={() => handleIconClick("info")}
            key={index}
            variant={"primary"}
          >
            <Icon />
          </IconHolder>
        ))}

        <FullScreenIcon cursor="pointer" onClick={handleFullScreen} />
      </ActionsWrapper>
    </StyledHeader>
  );
};

export default memo(Header);
