import { useMemo } from "react";
import { useAppSelector } from "../../redux/hooks";
import { useTranslate } from "../../utils/hooks";
import { StyledHistoryControl, StyledHistoryTable, UserName } from "./styles";

const HistoryControl = () => {
  const bets = useAppSelector((state) => state.game.bets);
  const translate = useTranslate();

  const userId = useMemo(() => {
    const id =
      new URLSearchParams(window.location.search).get("userID") || null;

    return Number(id) || null;
  }, [window.location.search]);

  const sortedBets = useMemo(() => {
    if (bets) {
      return bets.reduce<Bet[]>((acc, cur) => {
        if (userId === cur.user_id) {
          return [cur, ...acc];
        }

        return [...acc, cur];
      }, []);
    }

    return [];
  }, [bets]);

  return (
    <StyledHistoryControl>
      <StyledHistoryTable>
        <thead>
          <tr>
            <th>{translate("USER")}</th>
            <th>{translate("BET")}</th>
            <th>{translate("MULTIPLIER")}</th>
            <th>{translate("WIN")}</th>
          </tr>
        </thead>

        <tbody>
          {sortedBets.map(
            ({ id, amount, rate_auto, profit, user, user_id }) => (
              <tr key={id}>
                <UserName isCurrentUser={user_id === userId}>
                  {user?.first_name?.substring(0, 8) || user_id}
                </UserName>
                <td>{amount} $</td>
                <td>{rate_auto}</td>
                <td>{profit || "--"}</td>
              </tr>
            )
          )}
        </tbody>
      </StyledHistoryTable>
    </StyledHistoryControl>
  );
};

export default HistoryControl;
