import {
  BodyFAQModalHeader,
  FAQModalBody,
  StyledFAQModal,
  TopicInfo,
  TopicList,
  TopicListItem,
  Wrapper,
} from "./styles";
import { ReactComponent as ArrowRight } from "../../../assets/chevronRight.svg";
import { SyntheticEvent, useState } from "react";
import { TOPICS_EN, TOPICS_RU } from "./constants";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { toggleFAQModal } from "../../../redux/gameSlice";
import { useTranslate } from "../../../utils/hooks";

const topics = {
  EN: TOPICS_EN,
  RU: TOPICS_RU,
};

const FAQModal = () => {
  const language = useAppSelector((state) => state.game.language);
  const dispatch = useAppDispatch();
  const translate = useTranslate();
  const [activeTopic, setTopic] = useState("INTRODUCTION");

  const handleClose = (event: SyntheticEvent) => {
    //@ts-ignore
    if (["background"].includes(event.target.id)) {
      dispatch(toggleFAQModal(false));
    }
  };

  const data = topics[language];

  return (
    <Wrapper id="background" onClick={handleClose}>
      <StyledFAQModal>
        <BodyFAQModalHeader>{translate("GAME_HELP")}</BodyFAQModalHeader>

        <FAQModalBody>
          <TopicList>
            {Object.keys(data).map((title: any, index) => (
              <TopicListItem
                key={index}
                onClick={() => setTopic(title)}
                isActive={activeTopic === title}
              >
                {translate(title)}{" "}
                <ArrowRight style={{ minWidth: 20, minHeight: 20 }} />
              </TopicListItem>
            ))}
          </TopicList>

          <TopicInfo>
            {/* @ts-ignore */}
            {data[activeTopic].map((part, index) => (
              <div key={index}>
                <span>{part}</span>
              </div>
            ))}
          </TopicInfo>
        </FAQModalBody>
      </StyledFAQModal>
    </Wrapper>
  );
};

export default FAQModal;
