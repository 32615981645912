import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type initialStateProps = {
  language: Languages;
  bets: Bet[];
  tick: number | null;
  finalRate: number | null;
  balance: number | null;
  gameStatus: "pending" | "start" | "process" | "finish" | "prize";
  timeLeft: string | number;
  bet: string;
  betConfirmed: boolean;
  autoCashOut: {
    active: boolean;
    stopLimit: string;
  };
  halfAutoCashOut: {
    active: boolean;
    stopLimit: string;
  };
  manualCashOut: {
    prize: number;
    isTakenAutoCashOut: boolean;
    isTakenHalfAutoCashOut: boolean;
  };
  rateHistory: any[];
  isStatisticsOpen: boolean;
  isFAQOpen: boolean;
  isBalanceModalOpen: boolean;
};

const initialState: initialStateProps = {
  language: "RU",
  bets: [],
  tick: null,
  finalRate: null,
  balance: null,
  gameStatus: "pending",
  timeLeft: "",
  bet: window.localStorage.getItem("bet") || "5",
  betConfirmed: !!window.localStorage.getItem("isBetMade") || false,
  autoCashOut: window.localStorage.getItem("autoCashOut")
    ? JSON.parse(window.localStorage.getItem("autoCashOut")!)
    : {
        active: false,
        stopLimit: "2.00",
      },
  halfAutoCashOut: window.localStorage.getItem("halfAutoCashOut")
    ? JSON.parse(window.localStorage.getItem("halfAutoCashOut")!)
    : {
        active: false,
        stopLimit: "1.50",
      },
  manualCashOut: {
    prize: 0,
    isTakenAutoCashOut: false,
    isTakenHalfAutoCashOut: false,
  },
  rateHistory: [],
  isStatisticsOpen: false,
  isFAQOpen: false,
  isBalanceModalOpen: false,
};

export const gameSlice = createSlice({
  name: "game",
  initialState,
  reducers: {
    toggleCashOut: (
      state,
      action: PayloadAction<{ name: AutoCashOutFields; value: boolean }>
    ) => {
      const { name, value } = action.payload;

      state[name].active = value;
    },

    changeCashOutStopLimit: (
      state,
      action: PayloadAction<{ name: AutoCashOutFields; value: string }>
    ) => {
      const { name, value } = action.payload;

      state[name].stopLimit = value;
    },

    changeBet: (state, action: PayloadAction<string>) => {
      const newBet = action.payload;

      state.bet = newBet;
    },

    setGameStatus: (
      state,
      action: PayloadAction<initialStateProps["gameStatus"]>
    ) => {
      const newStatus = action.payload;

      state.gameStatus = newStatus;
    },

    setTick: (state, action: PayloadAction<initialStateProps["tick"]>) => {
      state.tick = action.payload;
    },

    confirmBet: (
      state,
      action: PayloadAction<initialStateProps["betConfirmed"]>
    ) => {
      state.betConfirmed = action.payload;
    },

    setFinalRate: (
      state,
      action: PayloadAction<initialStateProps["finalRate"]>
    ) => {
      state.finalRate = action.payload;
    },

    setTimeLeft: (
      state,
      action: PayloadAction<initialStateProps["timeLeft"]>
    ) => {
      state.timeLeft = action.payload;
    },

    setRateHistory: (
      state,
      action: PayloadAction<initialStateProps["rateHistory"]>
    ) => {
      state.rateHistory = action.payload;
    },

    setBalance: (
      state,
      action: PayloadAction<initialStateProps["balance"]>
    ) => {
      state.balance = action.payload;
    },

    setBets: (state, action: PayloadAction<initialStateProps["bets"]>) => {
      state.bets = action.payload;
    },

    toggleManualCashOut: (
      state,
      action: PayloadAction<{
        key: keyof initialStateProps["manualCashOut"];
        value: boolean | null | number;
      }>
    ) => {
      const { key, value } = action.payload;

      if (key !== "prize") {
        state.manualCashOut[key] = value as boolean;
      } else {
        state.manualCashOut.prize = value as number;
      }
    },

    toggleStatistics: (
      state,
      action: PayloadAction<initialStateProps["isStatisticsOpen"]>
    ) => {
      state.isStatisticsOpen = action.payload;
    },

    toggleBalanceModal: (
      state,
      action: PayloadAction<initialStateProps["isBalanceModalOpen"]>
    ) => {
      state.isBalanceModalOpen = action.payload;
    },

    toggleFAQModal: (
      state,
      action: PayloadAction<initialStateProps["isFAQOpen"]>
    ) => {
      state.isFAQOpen = action.payload;
    },

    changeLanguage: (
      state,
      action: PayloadAction<initialStateProps["language"]>
    ) => {
      state.language = action.payload;
    },
  },
});

export const {
  toggleCashOut,
  changeCashOutStopLimit,
  changeBet,
  setGameStatus,
  setTick,
  setFinalRate,
  setTimeLeft,
  confirmBet,
  setRateHistory,
  setBalance,
  setBets,
  toggleManualCashOut,
  toggleStatistics,
  toggleBalanceModal,
  toggleFAQModal,
  changeLanguage,
} = gameSlice.actions;

export default gameSlice.reducer;
