import styled, { keyframes } from "styled-components";
import greenPlanet from "../../../assets/greenPlanet.png";
import winPlace from "../../../assets/winPlace.png";
import winPlate from "../../../assets/winPlate.png";
import footerBackground from "../../../assets/footerBackground.png";
import footerOpacity from "../../../assets/footerOpacity.png";

const reduceHeight = keyframes`
  0% {
    height: 300px;
    opacity: 1
  }
  100% {
    height: 150px;
    opacity: 0
  }
`;

const increaseHeight = keyframes`
  0% {
    height: 150px;
    opacity: 0;
  }
  100% {
    height: 300px;
    opacity: 1;
  }
`;

const slideDown = keyframes`
  0% {
    transform: translate(0px, 0px);
    opacity: 1;
  }
  100% {
    transform: translate(0px, 50px);
    opacity: 0;
  }
`;

const slideUp = keyframes`
0% {
  transform: translate(0px, 50px)
  opacity: 1
}
100% {
  transform: translate(0px, 0px)
  opacity: 0
}
`;

export const StyledView = styled.div<{ fullHeight?: boolean }>(
  ({ fullHeight }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    zIndex: 3,
    position: "relative",
    height: fullHeight ? "100%" : "auto",
    overflow: "hidden",
    width: "100%",
  })
);

export const Timer = styled.div({
  gap: "30px",
  fontFamily: "Stolzl",
  fontSize: "36px",
  color: "white",
  fontWeight: 900,
  textAlign: "center",
});

export const Seconds = styled.span<{ isStartSoon: boolean }>(
  ({ isStartSoon }) => ({
    fontFamily: "Stolzl",
    fontSize: "30px",
    color: isStartSoon ? "#eb3131" : "rgb(255, 180, 0)",
    fontWeight: 900,
  })
);

export const Planet = styled.div({
  width: "290px",
  height: "150px",
  background: `url(${greenPlanet})`,
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
  display: "flex",
  alignItems: "center",
  marginBottom: "-90px",

  "@media screen and (max-width: 950px)": {
    width: "115px",
    height: "91px",
    marginBottom: "-40px",
  },
});

export const TickPlace = styled(Timer)({
  width: "100%",
  height: "60px",
  background: `url(${winPlace})`,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "45px",
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",

  "@media screen and (max-width: 950px)": {
    fontSize: "22px",
  },
});

export const RocketMan = styled.div<{ center?: boolean }>(({ center }) => ({
  zIndex: 2,
  width: "375px",
  height: "300px",
  display: "flex",
  justifyContent: "center",
  alignItems: center ? "center" : "flex-end",
  position: "relative",
}));

export const WinPlace = styled.div({
  display: "flex",
  flexDirection: "column",
  height: "645px",
  width: "555px",
  background: `url(${winPlate})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
  backgroundPosition: "center",
  position: "relative",

  "@media screen and  (max-width: 950px)": {
    marginTop: "20px",
    height: "360px",
    width: "375px",
  },
});

export const Rocket = styled.img({
  width: "140px",
  position: "absolute",
  right: 85,
  top: 0,

  "@media screen and  (max-width: 950px)": {
    width: "90px",
    right: 70,
    top: -20,
  },
});

export const WinTitle = styled.span({
  width: "100%",
  margin: "118px 0px 80px",
  textAlign: "center",
  color: "#E3FFDE",
  fontSize: "65px",
  fontWeight: "900",

  "@media screen and (max-width: 950px)": {
    margin: "50px 0px",
    fontSize: "40px",
  },
});

export const AutoCashOutWrapper = styled.div({
  display: "flex",
  flexDirection: "column",
  height: "130px",
  gap: "5px",

  "@media screen and (max-width: 950px)": {
    height: "90px",
  },
});

export const AutoCashOutItem = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "25px",
  color: "white",

  "& span:first-child": {
    fontSize: "50px",
    fontWeight: "900",
  },

  "& span:last-child": {
    width: "100px",
    fontSize: "18px",
  },

  "@media screen and (max-width: 950px)": {
    gap: "20px",
    paddingLeft: "25px",

    "& span:first-child": {
      fontSize: "30px",
      fontWeight: "900",
    },

    "& span:last-child": {
      width: "86px",
      fontSize: "13px",
    },
  },
});

export const PrizeAmount = styled(WinTitle)({
  marginTop: "17px",
  marginBottom: "0px",

  "@media screen and (max-width: 950px)": {
    marginTop: "8px",
  },
});

export const FooterStones = styled.div<{
  status: "start" | "end";
  gameStatus: "pending" | "start" | "process" | "finish" | "prize";
}>`
  width: 100%;
  height: 300px;
  position: absolute;
  bottom: 0px;
  display: ${({ gameStatus }) => (gameStatus === "process" ? "none" : "block")};
  background: url(${footerBackground});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  transform: ${({ gameStatus }) =>
    gameStatus === "process" ? "0!important" : 1};

  z-index: 1;
  animation: 1s cubic-bezier(1, 0.09, 0.83, 1)
    ${({ status }) => (status === "end" ? increaseHeight : reduceHeight)}
    forwards;
  animation-play-state: ${({ gameStatus }) =>
    ["start", "finish"].includes(gameStatus) ? "running" : "paused"};
`;

export const FooterOpacity = styled.div({
  display: "none",
  height: "300px",
  background: `url(${footerOpacity})`,
  backgroundSize: "cover",
  zIndex: 2,

  "@media screen and (max-width: 950px)": {
    display: "block",
  },
});
