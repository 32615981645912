import styled from "styled-components";
import arrowBackground from "../../assets/arrowBackground.png";
import arrowActiveBackground from "../../assets/arrowActiveBackground.png";
import buttonBackground from "../../assets/buttonBackground.png";
import buttonWarningBackground from "../../assets/buttonWarningBackground.png";

const backgroundColor = {
  primary: "#677AEC",
  success: `url(${arrowActiveBackground})`,
  transparent: `url(${arrowBackground})`,
  warning: `url(${buttonWarningBackground})`,
  fullWidthSuccess: `url(${buttonBackground})`,
};

const hightSize = {
  mobile: "39px",
  tab: `46px`,
  desktop: `46px`,
};

export const StyledButton = styled.button<{
  size: "desktop" | "tab" | "mobile";
  variant:
    | "primary"
    | "success"
    | "transparent"
    | "warning"
    | "fullWidthSuccess";
  fullWidth: boolean;
  disabled: boolean;
}>(({ variant, fullWidth, size }) => ({
  width: fullWidth ? "100%" : "max-content",
  height: "46px",
  display: "flex",
  alignItems: "center",
  gap: "16px",
  background: backgroundColor[variant],
  backgroundSize: "cover",
  borderRadius: "14px",
  justifyContent: "center",
  fontWeight: 800,
  fontSize: "16px",
  lineHeight: "28px",
  color: "white",
  cursor: "pointer",
  boxSizing: "border-box",
  border: "none",
  fontFamily: "Stolzl",

  "&:disabled": {
    background: "darkgray",
    pointerEvents: "none",
  },

  "@media screen and (max-width: 1128px)": {
    height: hightSize.tab,
    fontSize: "12px",
  },

  "@media screen and (max-width: 950px)": {
    fontSize: "12px",
    height: hightSize.mobile,
  },
}));
