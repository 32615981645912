import {
  changeCashOutStopLimit,
  toggleCashOut,
} from "../../../redux/gameSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import ArrowControls from "../../ArrowControls";
import { ReactComponent as PlusIcon } from "../../../assets/plusIcon.svg";
import { ReactComponent as CircleCheckIcon } from "../../../assets/circleCheckIcon.svg";
import {
  ArrowWrapper,
  ConfirmButton,
  ControlTitle,
  ControlWrapper,
  StyledArrowControl,
} from "./styles";
import { useTranslate } from "../../../utils/hooks";

const MobileArrowControl = () => {
  const betConfirmed = useAppSelector((state) => state.game.betConfirmed);
  const autoCashOut = useAppSelector((state) => state.game.autoCashOut);
  const halfAutoCashOut = useAppSelector((state) => state.game.halfAutoCashOut);
  const dispatch = useAppDispatch();
  const translate = useTranslate();

  const handleAutoCashOutChange = (value: string) => {
    if (+value || value === "") {
      dispatch(changeCashOutStopLimit({ name: "autoCashOut", value }));
    }
  };

  const handleAutoCashOutBlur = (value: string) => {
    if (value === "" || +value < 1.01) {
      dispatch(changeCashOutStopLimit({ name: "autoCashOut", value: "1.01" }));

      dispatch(
        changeCashOutStopLimit({ name: "halfAutoCashOut", value: "1.01" })
      );

      dispatch(toggleCashOut({ name: "halfAutoCashOut", value: false }));
    }
  };

  const handleHalfAutoCashOutChange = (value: string) => {
    if (
      autoCashOut.active &&
      halfAutoCashOut.active &&
      +value > +autoCashOut.stopLimit - 0.01
    )
      return;

    if (+value || value === "") {
      dispatch(changeCashOutStopLimit({ name: "halfAutoCashOut", value }));
    }
  };

  const handleHalfAutoCashOutBlur = (value: string) => {
    if (value === "") {
      dispatch(
        changeCashOutStopLimit({ name: "halfAutoCashOut", value: "1.01" })
      );
    }
  };

  const changeBetAmount = (
    action: AutoCashOutAction,
    name: AutoCashOutFields
  ) => {
    if (name === "autoCashOut") {
      const preventValue = +autoCashOut.stopLimit;

      if (action === "decrease" && preventValue - 0.01 > 1) {
        const newValue = preventValue - 0.01;

        dispatch(
          changeCashOutStopLimit({
            name: "autoCashOut",
            value: String(newValue.toFixed(2)),
          })
        );

        if (newValue === 1.01) {
          dispatch(
            toggleCashOut({
              name: "halfAutoCashOut",
              value: false,
            })
          );
        }
      }

      if (action === "increase" && preventValue + 0.01 <= 999.99) {
        const newValue = preventValue + 0.01;

        dispatch(
          changeCashOutStopLimit({
            name: "autoCashOut",
            value: String(newValue.toFixed(2)),
          })
        );
      }
    }

    if (name === "halfAutoCashOut") {
      const preventValue = +halfAutoCashOut.stopLimit;

      if (action === "increase") {
        if (
          autoCashOut.active &&
          preventValue + 0.01 < +autoCashOut.stopLimit
        ) {
          const newValue = preventValue + 0.01;

          dispatch(
            changeCashOutStopLimit({
              name: "halfAutoCashOut",
              value: String(newValue.toFixed(2)),
            })
          );
        }

        if (!autoCashOut.active) {
          const newValue = preventValue + 0.01;

          dispatch(
            changeCashOutStopLimit({
              name: "halfAutoCashOut",
              value: String(newValue.toFixed(2)),
            })
          );
        }
      }

      if (action === "decrease" && preventValue - 0.01 > 1) {
        const newValue = preventValue - 0.01;

        dispatch(
          changeCashOutStopLimit({
            name: "halfAutoCashOut",
            value: String(newValue.toFixed(2)),
          })
        );
      }
    }
  };

  const handleCashOut = (name: AutoCashOutFields) => {
    if (name === "autoCashOut") {
      dispatch(
        toggleCashOut({ name: "autoCashOut", value: !autoCashOut.active })
      );

      if (+autoCashOut.stopLimit === 1.01) {
        dispatch(toggleCashOut({ name: "halfAutoCashOut", value: false }));
      }

      if (+autoCashOut.stopLimit <= +halfAutoCashOut.stopLimit) {
        dispatch(
          changeCashOutStopLimit({
            name: "halfAutoCashOut",
            value: String((+autoCashOut.stopLimit - 0.01).toFixed(2)),
          })
        );
      }
    }

    if (name === "halfAutoCashOut") {
      if (+autoCashOut.stopLimit === 1.01) return;

      if (+autoCashOut.stopLimit > 1.01) {
        dispatch(
          toggleCashOut({
            name: "halfAutoCashOut",
            value: !halfAutoCashOut.active,
          })
        );
      }

      if (
        autoCashOut.active &&
        +halfAutoCashOut.stopLimit >= +autoCashOut.stopLimit
      ) {
        dispatch(
          toggleCashOut({
            name: "halfAutoCashOut",
            value: !halfAutoCashOut.active,
          })
        );

        dispatch(
          changeCashOutStopLimit({
            name: "halfAutoCashOut",
            value: String((+autoCashOut.stopLimit - 0.01).toFixed(2)),
          })
        );
      }

      if (!autoCashOut.active) {
        dispatch(
          toggleCashOut({
            name: "halfAutoCashOut",
            value: !halfAutoCashOut.active,
          })
        );
      }
    }
  };

  return (
    <StyledArrowControl>
      <ControlWrapper>
        <ConfirmButton
          disabled={betConfirmed}
          onClick={() => handleCashOut("autoCashOut")}
          isActive={autoCashOut.active}
        >
          {autoCashOut.active ? (
            <CircleCheckIcon width={30} height={30} />
          ) : (
            <PlusIcon width={30} height={30} />
          )}
        </ConfirmButton>

        <ArrowWrapper isDisabled={betConfirmed}>
          <ControlTitle>{translate("AUTO_CASHOUT")}</ControlTitle>

          <ArrowControls
            suffix="X"
            digitsAfterDote={2}
            value={autoCashOut.stopLimit}
            handleChange={handleAutoCashOutChange}
            handleBlur={handleAutoCashOutBlur}
            handleDecrease={() => changeBetAmount("decrease", "autoCashOut")}
            handleIncrease={() => changeBetAmount("increase", "autoCashOut")}
          />
        </ArrowWrapper>
      </ControlWrapper>

      <ControlWrapper>
        <ConfirmButton
          disabled={betConfirmed}
          onClick={() => handleCashOut("halfAutoCashOut")}
          isActive={halfAutoCashOut.active}
        >
          {halfAutoCashOut.active ? (
            <CircleCheckIcon width={30} height={30} />
          ) : (
            <PlusIcon width={30} height={30} />
          )}
        </ConfirmButton>

        <ArrowWrapper isDisabled={betConfirmed}>
          <ControlTitle>50% {translate("AUTO_CASHOUT")}</ControlTitle>

          <ArrowControls
            suffix="X"
            value={halfAutoCashOut.stopLimit}
            digitsAfterDote={2}
            handleChange={handleHalfAutoCashOutChange}
            handleBlur={handleHalfAutoCashOutBlur}
            handleDecrease={() =>
              changeBetAmount("decrease", "halfAutoCashOut")
            }
            handleIncrease={() =>
              changeBetAmount("increase", "halfAutoCashOut")
            }
          />
        </ArrowWrapper>
      </ControlWrapper>
    </StyledArrowControl>
  );
};

export default MobileArrowControl;
