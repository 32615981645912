import { RocketMan, Seconds, StyledView, Timer } from "./styles";
import { useAppSelector } from "../../../redux/hooks";
import defaultPending from "../../../assets/character/defaultPending.gif";
import enterTheRocket from "../../../assets/character/enterTheRocket.gif";
import rocketTail from "../../../assets/character/strokesStart.gif";
import { useTranslate } from "../../../utils/hooks";

const PendingView = () => {
  const timeLeft = useAppSelector((state) => state.game.timeLeft);
  const translate = useTranslate();

  return (
    <StyledView fullHeight>
      <Timer>
        {translate("NEXT_GAME_IN")}{" "}
        <Seconds isStartSoon={+timeLeft <= 5}>
          {timeLeft ? `${timeLeft}s` : ""}
        </Seconds>
      </Timer>

      <RocketMan>
        {timeLeft && +timeLeft > 2 && (
          <img width="100px" src={defaultPending} alt="astronaut" />
        )}
        {timeLeft && +timeLeft <= 2 && (
          <>
            {+timeLeft <= 1 && (
              <img
                style={{
                  position: "absolute",
                  left: -280,
                  top: 190,
                  width: "100%",
                }}
                src={rocketTail}
                alt="rocketTail"
              />
            )}

            <img
              width="400px"
              height="300px"
              src={enterTheRocket}
              alt="astronaut"
            />
          </>
        )}
      </RocketMan>
    </StyledView>
  );
};

export default PendingView;
