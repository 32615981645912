// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/Stolzl-Regular.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "* {\n  padding: 0;\n  margin: 0;\n  box-sizing: border-box;\n  -ms-overflow-style: none;  /* Internet Explorer 10+ */\n    scrollbar-width: none;  /* Firefox */\n}\n\n*::-webkit-scrollbar{\n  display: none;  /* Safari and Chrome */\n}\n\n@font-face {\n  font-family: \"Stolzl\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\");\n  font-weight: 400;\n}\n\nbody {\n  margin: 0;\n  font-family: Stolzl, sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  \n}", "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,SAAS;EACT,sBAAsB;EACtB,wBAAwB,GAAG,0BAA0B;IACnD,qBAAqB,GAAG,YAAY;AACxC;;AAEA;EACE,aAAa,GAAG,sBAAsB;AACxC;;AAEA;EACE,qBAAqB;EACrB,+DAAwD;EACxD,gBAAgB;AAClB;;AAEA;EACE,SAAS;EACT,+BAA+B;EAC/B,mCAAmC;EACnC,kCAAkC;;AAEpC","sourcesContent":["* {\n  padding: 0;\n  margin: 0;\n  box-sizing: border-box;\n  -ms-overflow-style: none;  /* Internet Explorer 10+ */\n    scrollbar-width: none;  /* Firefox */\n}\n\n*::-webkit-scrollbar{\n  display: none;  /* Safari and Chrome */\n}\n\n@font-face {\n  font-family: \"Stolzl\";\n  src: url(./assets/Stolzl-Regular.otf) format(\"truetype\");\n  font-weight: 400;\n}\n\nbody {\n  margin: 0;\n  font-family: Stolzl, sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
