import styled from "styled-components";

export const StyledManualCashOut = styled.div({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  alignItems: "center",
  width: "100%",
  height: "46px",
  background: "#76CC84",
  borderRadius: "14px",
  boxSizing: "border-box",

  "@media screen and (max-width: 950px)": {
    height: "40px",
  },
});

export const CashOutButton = styled.button<{ borderRadius: string }>(
  ({ borderRadius }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "4px",
    width: "100%",
    height: "100%",
    background: "transparent",
    border: "none",
    outline: "none",
    cursor: "pointer",
    borderRadius,

    "&:first-child": {
      borderRight: "2px solid white",
    },

    "&:disabled": {
      cursor: "not-allowed",
      background: "darkgray",
    },

    "@media screen and (max-width: 950px)": {
      gap: "3px",
    },
  })
);

export const CashOutTitle = styled.span({
  fontFamily: "Stolzl",
  fontSize: "14px",
  color: "white",
  fontWeight: 900,

  "@media screen and (max-width: 950px)": {
    fontSize: "11px",
  },
});

export const CashOutValue = styled.span({
  fontFamily: "Stolzl",
  fontSize: "14px",
  color: "white",
  fontWeight: 900,

  "@media screen and (max-width: 950px)": {
    fontSize: "11px",
  },
});
