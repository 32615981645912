import { Planet, RocketMan, StyledView, TickPlace } from "./styles";
import { useAppSelector } from "../../../redux/hooks";
// import rocketMan from "../../../assets/rocketMan.svg";
import flyOnRocket from "../../../assets/character/flyOnRocket.gif";
import rocketTail from "../../../assets/character/strokes.gif";

const ProcessView = () => {
  const tick = useAppSelector((state) => state.game.tick);

  return (
    <StyledView fullHeight>
      <div style={{ height: "43px" }}>
        <Planet>
          <TickPlace>X{tick}</TickPlace>
        </Planet>
      </div>

      <RocketMan center={true}>
        <img
          style={{
            position: "absolute",
            left: -285,
            top: 200,
            width: "100%",
          }}
          src={rocketTail}
          alt="rocketTail"
        />

        <img
          style={{
            width: "200px",
            height: "200px",
            marginLeft: "-50px",
            marginTop: "-35px",
          }}
          src={flyOnRocket}
          alt="astronaut"
        />
      </RocketMan>
    </StyledView>
  );
};

export default ProcessView;
