import { memo } from "react";
import Button from "../Button";
import IconHolder from "../IconHolder";
import ArrowControls from "../ArrowControls";
import ManualCashOut from "../ManualCashOut";
import {
  ActionsWrapper,
  BetWrapper,
  ButtonsWrapper,
  StyledBetControl,
  TextWrapper,
} from "./styles";
import {
  changeBet,
  confirmBet,
  toggleBalanceModal,
  toggleStatistics,
} from "../../redux/gameSlice";
import { ReactComponent as PlusIcon } from "../../assets/plusIcon.svg";
import { ReactComponent as CheckIcon } from "../../assets/checkIcon.svg";
import { ReactComponent as DoubleIcon } from "../../assets/doubleIcon.svg";
// import { ReactComponent as SwapIcon } from "../../assets/swapIcon.svg";
import { ReactComponent as DiagramIcon } from "../../assets/diagromIcon.svg";
import { ReactComponent as CancelIcon } from "../../assets/cancelIcon.svg";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { SocketIO } from "../../redux/gameService";
import { useTranslate } from "../../utils/hooks";

const BetControl = () => {
  const bet = useAppSelector((state) => state.game.bet);
  const betConfirmed = useAppSelector((state) => state.game.betConfirmed);
  const gameStatus = useAppSelector((state) => state.game.gameStatus);
  const balance = useAppSelector((state) => state.game.balance);
  const translate = useTranslate();
  const isStatisticsOpen = useAppSelector(
    (state) => state.game.isStatisticsOpen
  );
  const dispatch = useAppDispatch();

  const changeBetAmount = (action: AutoCashOutAction, amount?: number) => {
    let newValue = +bet;

    if (action === "decrease" && newValue - 1 >= 1) {
      newValue -= 1;
    }

    if (!amount && action === "increase" && newValue + 1 <= 100) {
      newValue += 1;
    }

    if (amount) {
      newValue = newValue + amount > 100 ? 100 : newValue + amount;
    }

    dispatch(changeBet(String(newValue)));
  };

  const handleChange = (value: string) => {
    if ((+value && +value <= 100) || value === "") {
      dispatch(changeBet(value));
    }
  };

  const handleBlur = (value: string) => {
    if (value === "") {
      dispatch(changeBet("1"));
    }
  };

  const handleBetConfirm = () => {
    if (balance! < +bet && !betConfirmed) {
      dispatch(toggleBalanceModal(true));

      return;
    }

    if (betConfirmed) {
      console.log("cancel");

      dispatch(confirmBet(false));

      SocketIO.cancelBet();
    }

    if (!betConfirmed) {
      console.log("confirm");
      dispatch(confirmBet(true));

      SocketIO.betConfirm();
    }
  };

  const handleMultiplication = () => {
    const value = +bet * 2 > 100 ? 100 : +bet * 2;

    dispatch(changeBet(String(value)));
  };

  const handleToggleStatistics = () => {
    dispatch(toggleStatistics(!isStatisticsOpen));
  };

  return (
    <StyledBetControl>
      <BetWrapper>
        <TextWrapper>
          <span>{translate("BET")}:</span>

          <div style={{ pointerEvents: betConfirmed ? "none" : "auto" }}>
            <ArrowControls
              value={bet}
              handleChange={handleChange}
              handleBlur={handleBlur}
              handleDecrease={() => changeBetAmount("decrease")}
              handleIncrease={() => changeBetAmount("increase")}
            />
          </div>
        </TextWrapper>

        <ActionsWrapper>
          <IconHolder width="40px" height="40px">
            <DoubleIcon onClick={handleMultiplication} />
          </IconHolder>

          {/* <IconHolder width="40px" height="40px">
            <SwapIcon />
          </IconHolder> */}

          <IconHolder width="40px" height="40px">
            <DiagramIcon onClick={handleToggleStatistics} />
          </IconHolder>
        </ActionsWrapper>
      </BetWrapper>

      <ButtonsWrapper>
        <Button
          disabled={betConfirmed}
          fullWidth
          handleClick={() => changeBetAmount("increase", 5)}
          variant="transparent"
          rightIcon={<PlusIcon cursor="pointer" />}
        >
          5 $
        </Button>

        <Button
          disabled={betConfirmed}
          fullWidth
          handleClick={() => changeBetAmount("increase", 10)}
          variant="transparent"
          rightIcon={<PlusIcon cursor="pointer" />}
        >
          10 $
        </Button>

        <Button
          disabled={betConfirmed}
          fullWidth
          handleClick={() => changeBetAmount("increase", 25)}
          variant="transparent"
          rightIcon={<PlusIcon cursor="pointer" />}
        >
          25 $
        </Button>
      </ButtonsWrapper>

      {!["pending", "start"].includes(gameStatus) && betConfirmed ? (
        <ManualCashOut />
      ) : (
        <Button
          disabled={["process", "finish"].includes(gameStatus)}
          fullWidth
          leftIcon={betConfirmed ? <CancelIcon /> : <CheckIcon />}
          variant={betConfirmed ? "warning" : "fullWidthSuccess"}
          handleClick={handleBetConfirm}
        >
          {translate(betConfirmed ? "CANCEL" : "CONFIRM_BET")}
        </Button>
      )}
    </StyledBetControl>
  );
};

export default memo(BetControl);
