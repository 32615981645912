import { useState } from "react";
import { useAppSelector } from "../../redux/hooks";
import {
  CountRange,
  HistoryWrapper,
  RateItem,
  StyledStatistics,
  Title,
} from "./styles";
import { ReactComponent as DiagramIcon } from "../../assets/diagromIcon.svg";
import { BACKGROUNDS } from "../../constants";

const Statistics = () => {
  const [rateCounter, setRateCounter] = useState(50);
  const history = useAppSelector((state) => state.game.rateHistory);

  return (
    <StyledStatistics>
      <Title>Результаты</Title>

      <HistoryWrapper>
        {history.map(({ id, rate_final }, index) => (
          <RateItem key={id} background={BACKGROUNDS[index]}>
            {parseFloat(rate_final.toFixed(2))}
          </RateItem>
        ))}
      </HistoryWrapper>

      <CountRange>
        <DiagramIcon />

        {/* <input
          min={0}
          max={100}
          type="range"
          value={rateCounter}
          onChange={({ target }) => setRateCounter(+target.value)}
        />

        <span>{rateCounter}</span> */}
      </CountRange>
    </StyledStatistics>
  );
};

export default Statistics;
