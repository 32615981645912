import "./styles.ts";
import {
  AutoControl,
  ModalContainer,
  BetControl,
  Footer,
  Game,
  Header,
  HistoryControl,
} from "./components";
import { Controls, ControlsWrapper, StyledApp } from "./styles";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import { MobileGamePad } from "./components/index";
import Canvas from "./components/Canvas";
import Statistics from "./components/Statistics";
import { useEffect } from "react";
import { changeLanguage } from "./redux/gameSlice";
import StonesFooter from "./components/Game/components/StonesFooter";

const App = () => {
  const dispatch = useAppDispatch();
  const isStatisticsOpen = useAppSelector(
    (state) => state.game.isStatisticsOpen
  );

  useEffect(() => {
    const language =
      (new URLSearchParams(window.location.search).get(
        "language"
      ) as Languages) || "RU";

    dispatch(changeLanguage(language));
  }, [window.location.search]);

  return (
    <StyledApp>
      <ModalContainer />

      <Canvas />

      <Header />

      <Game />

      <MobileGamePad />

      <ControlsWrapper>
        {isStatisticsOpen && <Statistics />}

        <Controls>
          <BetControl />

          <AutoControl />

          <HistoryControl />
        </Controls>

        <Footer />
      </ControlsWrapper>

      <StonesFooter />
    </StyledApp>
  );
};

export default App;
