import { Control, StyledAutoControl, StyledPlusIcon } from "./styles";
import Button from "../Button";
import { changeCashOutStopLimit, toggleCashOut } from "../../redux/gameSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import ArrowControls from "../ArrowControls";
import { useTranslate } from "../../utils/hooks";

const AutoControl = () => {
  const dispatch = useAppDispatch();
  const autoCashOut = useAppSelector((state) => state.game.autoCashOut);
  const halfAutoCashOut = useAppSelector((state) => state.game.halfAutoCashOut);
  const betConfirmed = useAppSelector((state) => state.game.betConfirmed);
  const translate = useTranslate();

  const handleCashOut = (name: AutoCashOutFields) => {
    if (name === "autoCashOut") {
      dispatch(
        toggleCashOut({ name: "autoCashOut", value: !autoCashOut.active })
      );

      if (+autoCashOut.stopLimit === 1.01) {
        dispatch(toggleCashOut({ name: "halfAutoCashOut", value: false }));
      }

      if (+autoCashOut.stopLimit <= +halfAutoCashOut.stopLimit) {
        dispatch(
          changeCashOutStopLimit({
            name: "halfAutoCashOut",
            value: String((+autoCashOut.stopLimit - 0.01).toFixed(2)),
          })
        );
      }
    }

    if (name === "halfAutoCashOut") {
      if (+autoCashOut.stopLimit === 1.01) return;

      if (+autoCashOut.stopLimit > 1.01) {
        dispatch(
          toggleCashOut({
            name: "halfAutoCashOut",
            value: !halfAutoCashOut.active,
          })
        );
      }

      if (
        autoCashOut.active &&
        +halfAutoCashOut.stopLimit >= +autoCashOut.stopLimit
      ) {
        dispatch(
          toggleCashOut({
            name: "halfAutoCashOut",
            value: !halfAutoCashOut.active,
          })
        );

        dispatch(
          changeCashOutStopLimit({
            name: "halfAutoCashOut",
            value: String((+autoCashOut.stopLimit - 0.01).toFixed(2)),
          })
        );
      }

      if (!autoCashOut.active) {
        dispatch(
          toggleCashOut({
            name: "halfAutoCashOut",
            value: !halfAutoCashOut.active,
          })
        );
      }
    }
  };

  const changeBetAmount = (
    action: AutoCashOutAction,
    name: AutoCashOutFields
  ) => {
    if (name === "autoCashOut") {
      const preventValue = +autoCashOut.stopLimit;

      if (action === "decrease" && preventValue - 0.01 > 1) {
        const newValue = preventValue - 0.01;

        dispatch(
          changeCashOutStopLimit({
            name: "autoCashOut",
            value: String(newValue.toFixed(2)),
          })
        );

        if (newValue === 1.01 || newValue < +halfAutoCashOut.stopLimit) {
          dispatch(
            toggleCashOut({
              name: "halfAutoCashOut",
              value: false,
            })
          );
        }
      }

      if (action === "increase" && preventValue + 0.01 <= 999.99) {
        const newValue = preventValue + 0.01;

        console.log(newValue);

        dispatch(
          changeCashOutStopLimit({
            name: "autoCashOut",
            value: String(newValue.toFixed(2)),
          })
        );
      }
    }

    if (name === "halfAutoCashOut") {
      const preventValue = +halfAutoCashOut.stopLimit;

      if (action === "increase") {
        if (
          autoCashOut.active &&
          preventValue + 0.01 < +autoCashOut.stopLimit
        ) {
          const newValue = preventValue + 0.01;

          dispatch(
            changeCashOutStopLimit({
              name: "halfAutoCashOut",
              value: String(newValue.toFixed(2)),
            })
          );
        }

        if (!autoCashOut.active) {
          const newValue = preventValue + 0.01;

          dispatch(
            changeCashOutStopLimit({
              name: "halfAutoCashOut",
              value: String(newValue.toFixed(2)),
            })
          );
        }
      }

      if (action === "decrease" && preventValue - 0.01 > 1) {
        const newValue = preventValue - 0.01;

        dispatch(
          changeCashOutStopLimit({
            name: "halfAutoCashOut",
            value: String(newValue.toFixed(2)),
          })
        );
      }
    }
  };

  const handleAutoCashOutChange = (value: string) => {
    if (+value || value === "") {
      dispatch(changeCashOutStopLimit({ name: "autoCashOut", value }));
    }
  };

  const handleHalfAutoCashOutChange = (value: string) => {
    if (
      autoCashOut.active &&
      halfAutoCashOut.active &&
      +value >= +autoCashOut.stopLimit
    )
      return;

    if (+value || value === "") {
      dispatch(changeCashOutStopLimit({ name: "halfAutoCashOut", value }));
    }
  };

  const handleAutoCashOutBlur = (value: string) => {
    if (value === "" || +value < 1.01) {
      dispatch(changeCashOutStopLimit({ name: "autoCashOut", value: "1.01" }));

      dispatch(
        changeCashOutStopLimit({ name: "halfAutoCashOut", value: "1.01" })
      );

      dispatch(toggleCashOut({ name: "halfAutoCashOut", value: false }));
    }
  };

  const handleHalfAutoCashOutBlur = (value: string) => {
    if (value === "" || +value < 1.01) {
      dispatch(
        changeCashOutStopLimit({ name: "halfAutoCashOut", value: "1.01" })
      );
    }
  };

  return (
    <StyledAutoControl>
      <Control>
        <StyledPlusIcon
          onClick={() =>
            betConfirmed ? undefined : handleCashOut("autoCashOut")
          }
          active={autoCashOut.active ? "#76CC84" : undefined}
        />

        <p>{translate("AUTO_CASHOUT")}</p>

        <Button
          disabled={betConfirmed}
          variant={autoCashOut.active ? "success" : "transparent"}
        >
          <ArrowControls
            suffix="x"
            digitsAfterDote={2}
            value={autoCashOut.stopLimit}
            handleChange={handleAutoCashOutChange}
            handleBlur={handleAutoCashOutBlur}
            handleDecrease={() => changeBetAmount("decrease", "autoCashOut")}
            handleIncrease={() => changeBetAmount("increase", "autoCashOut")}
          />
        </Button>
      </Control>

      <Control>
        <StyledPlusIcon
          onClick={() =>
            betConfirmed || +autoCashOut.stopLimit <= 1.01
              ? undefined
              : handleCashOut("halfAutoCashOut")
          }
          active={halfAutoCashOut.active ? "#76CC84" : undefined}
        />

        <p>50% {translate("AUTO_CASHOUT")}</p>

        <Button
          disabled={betConfirmed}
          variant={halfAutoCashOut.active ? "success" : "transparent"}
        >
          <ArrowControls
            suffix="x"
            digitsAfterDote={2}
            value={halfAutoCashOut.stopLimit}
            handleChange={handleHalfAutoCashOutChange}
            handleBlur={handleHalfAutoCashOutBlur}
            handleDecrease={() =>
              changeBetAmount("decrease", "halfAutoCashOut")
            }
            handleIncrease={() =>
              changeBetAmount("increase", "halfAutoCashOut")
            }
          />
        </Button>
      </Control>
    </StyledAutoControl>
  );
};

export default AutoControl;
