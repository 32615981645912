import { ReactElement } from "react";
import { StyledIconHolder } from "./styles";

type IconHolderProps = {
  children: ReactElement;
  variant?: "default" | "primary" | "transparent" | "mobile";
  onClick?: () => void;
  width?: string;
  height?: string;
};

const IconHolder: React.FC<IconHolderProps> = ({
  children,
  variant = "default",
  onClick,
  width = "50px",
  height = "50px",
}) => {
  return (
    <StyledIconHolder
      width={width}
      height={height}
      onClick={onClick}
      variant={variant}
    >
      {children}
    </StyledIconHolder>
  );
};

export default IconHolder;
