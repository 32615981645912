import styled from "styled-components";

export const StyledMobileGamePad = styled.div({
  display: "none",
  flexDirection: "column",
  justifyContent: "center",
  gap: "10px",
  paddingBottom: "20px",
  zIndex: 3,
  position: "relative",

  "@media screen and (max-width: 950px)": {
    display: "flex",
    alignItems: "center",
  },
});
