import styled from "styled-components";

export const StyledBetControl = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: "4px",
});

export const ButtonsWrapper = styled.div({
  width: "100%",
  display: "flex",
  gap: "7px",
  marginBottom: "2px",
});

export const BetWrapper = styled.div({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

export const TextWrapper = styled.div({
  display: "flex",
  gap: "12px",
  alignItems: "center",
  justifyContent: "center",
  color: "white",
  fontWeight: 900,
  paddingLeft: "20px",

  "& span": {
    textAlign: "center",
  },
});

export const ActionsWrapper = styled.div({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "6px",
});
