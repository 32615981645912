import { RefObject, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Dictionary from "../dictionary";
import { RootState } from "../redux/store";

export const useOutsideClick = (initialIsVisible: boolean) => {
  const [isVisible, setIsVisible] = useState(initialIsVisible);
  const ref = useRef(null);

  const handleClickOutside = (event: MouseEvent) => {
    let element = ref as RefObject<HTMLElement>;

    if (element.current && !element.current.contains(event.target as Node)) {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return { ref, isVisible, setIsVisible };
};

export const useTranslate = (): ((
  key: keyof (typeof Dictionary)["RU"]
) => string) => {
  const language = useSelector((state: RootState) => state.game.language);

  const translate = (key: keyof (typeof Dictionary)["RU"]): string => {
    return Dictionary[language][key];
  };

  return translate;
};
