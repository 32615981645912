import styled from "styled-components";

export const StyledHistoryControl = styled.div({
  width: "100%",
  height: "142px",
  overflow: "scroll",
  boxSizing: "border-box",

  "@media screen and (max-width: 1128px)": {
    height: "142px",
    fontSize: "12px",
  },

  "@media screen and (max-width: 950px)": {
    width: "320px",
    height: "120px",
    fontSize: "12px",
  },
});

export const StyledHistoryTable = styled.table({
  width: "100%",
  color: "white",

  "& th": {
    fontSize: "14px",
  },

  "& thead tr": {
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    gridAutoRows: "30px",
    fontWeight: 900,

    "th:first-child": {
      textAlign: "left",
    },

    "th:last-child": {
      textAlign: "right",
    },
  },

  "& tbody tr": {
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    gridAutoRows: "30px",
    textAlign: "center",
    color: "#96ADCD",
    // fontWeight: "bolder",

    "td:first-child": {
      textAlign: "left",
    },

    "td:last-child": {
      textAlign: "right",
    },
  },

  "@media screen and (max-width: 1128px)": {
    "& th": {
      fontSize: "12px",
    },
  },
});

export const UserName = styled.td<{ isCurrentUser: boolean }>(
  ({ isCurrentUser }) => ({
    color: isCurrentUser ? "white" : "#96ADCD",
    fontWeight: "bolder",
    textDecoration: isCurrentUser ? "underline" : "none",
  })
);
