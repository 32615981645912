import { configureStore } from "@reduxjs/toolkit";
import { userApi } from "./userApi";
import userSlice from "./userSlice";
import gameSlice from "./gameSlice";
// import { gameApi } from "./gameApi";

export const gameStore = configureStore({
  reducer: {
    user: userSlice,
    userApi: userApi.reducer,
    game: gameSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(userApi.middleware),
});

export type RootState = ReturnType<typeof gameStore.getState>;
export type AppDispatch = typeof gameStore.dispatch;
