import { memo } from "react";
import { BACKGROUNDS } from "../../constants";
import { useAppSelector } from "../../redux/hooks";
import { FooterItem, StyledFooter } from "./styles";

const Footer = () => {
  const rateHistory = useAppSelector((state) => state.game.rateHistory);

  return (
    <StyledFooter>
      {rateHistory.map(({ id, rate_final }, index) => (
        <FooterItem key={id} background={BACKGROUNDS[index]}>
          {+rate_final.toFixed(2) || ""}
        </FooterItem>
      ))}
    </StyledFooter>
  );
};

export default memo(Footer);
