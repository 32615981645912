import planet from "./assets/greenPlanet.png";
import planetBlue from "./assets/bluePlanet.png";
import planetPurple from "./assets/purplePlanet.png";
import bigPlanet from "./assets/bigPlanet.png";
import orangePlanet from "./assets/orangePlanet.png";
import { gameStore } from "./redux/store";

const planetsArray = [
  planet,
  planetBlue,
  planetPurple,
  bigPlanet,
  orangePlanet,
];

class Planet {
  x: number;
  y: number;
  vx: number;
  vy: number;
  speed: number;
  size: number;
  angle: number;
  spin: number;
  img: HTMLImageElement;
  canvas: HTMLCanvasElement;

  constructor(effectClass: Effect, img: string) {
    let image = new Image();
    image.src = img;

    this.canvas = effectClass.canvas;
    this.img = image;
    this.x = Math.random() * effectClass.width;
    this.y = Math.random() * (effectClass.height / 3);
    this.size = Math.random() * (150 - 60) + 60;
    this.speed = Math.random() * (10 - 1) + 1;
    this.angle = Math.random() * 360;
    this.spin = Math.random() > 0.5 ? 1 : -1;
    this.vx = 4;
    this.vy = 1;
  }

  update() {
    this.angle++;

    const gameStatus = gameStore.getState().game.gameStatus;

    if (gameStatus === "start" || gameStatus === "process") {
      if (this.x < 0 - this.size) {
        this.x = this.canvas.width;
        this.y = Math.random() * (this.canvas.height / 3 - 0) + 0;
        this.size = Math.random() * (150 - 60) + 60;
        this.speed = Math.random() * (10 - 1) + 1;
        this.spin = Math.random() > 0.5 ? 1 : -1;
      }

      this.x -= this.speed;
      this.y += this.speed / 6;
    }
  }

  draw(context: CanvasRenderingContext2D) {
    context.save();
    context.translate(this.x, this.y);
    context.rotate(this.angle * (Math.PI / 360) * this.spin);
    context.drawImage(
      this.img,
      0 - this.size / 2,
      0 - this.size / 2,
      this.size,
      this.size
    );
    context.restore();
  }
}

class Effect {
  canvas: HTMLCanvasElement;
  ctx: CanvasRenderingContext2D;
  width: number;
  height: number;
  planets: Planet[] = [];

  constructor(canvas: HTMLCanvasElement) {
    this.canvas = canvas;
    this.ctx = canvas.getContext("2d")!;
    this.width = canvas.width;
    this.height = canvas.height;
  }

  init() {
    planetsArray.forEach((planetImage) =>
      this.planets.push(new Planet(this, planetImage))
    );
  }

  update() {
    this.planets.forEach((planet) => planet.update());
  }

  draw() {
    this.planets.forEach((planet) => planet.draw(this.ctx));
  }
}

const animationInit = () => {
  const canvas = document.getElementById("canvas") as HTMLCanvasElement;
  canvas.height = 660;
  const canvasService = new Effect(canvas);

  canvasService.init();

  const animation = () => {
    canvas?.getContext("2d")?.clearRect(0, 0, canvas.width, canvas.height);

    canvasService.update();
    canvasService.draw();

    // setTimeout(() => {
    requestAnimationFrame(animation);
    // }, 1000 / 60);
  };

  animation();
};

export { animationInit };
