import {
  changeBet,
  confirmBet,
  toggleBalanceModal,
  toggleStatistics,
} from "../../../redux/gameSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import ArrowControls from "../../ArrowControls";
import Button from "../../Button";
import {
  Actions,
  ActionsWrapper,
  BetWrapper,
  ButtonWrapper,
  StyledBetControl,
} from "./styles";
import { ReactComponent as PlusIcon } from "../../../assets/plusIcon.svg";
import IconHolder from "../../IconHolder";
import { ReactComponent as DoubleIcon } from "../../../assets/doubleIcon.svg";
// import { ReactComponent as SwapIcon } from "../../../assets/swapIcon.svg";
import { ReactComponent as DiagramIcon } from "../../../assets/diagromIcon.svg";
import { ReactComponent as CancelIcon } from "../../../assets/cancelIcon.svg";
import { ReactComponent as CheckIcon } from "../../../assets/checkIcon.svg";
import { SocketIO } from "../../../redux/gameService";
import ManualCashOut from "../../ManualCashOut";
import { useTranslate } from "../../../utils/hooks";

const buttonProps = [5, 15, 25, 50];

const MobileBetControl = () => {
  const balance = useAppSelector((state) => state.game.balance);
  const gameStatus = useAppSelector((state) => state.game.gameStatus);
  const bet = useAppSelector((state) => state.game.bet);
  const betConfirmed = useAppSelector((state) => state.game.betConfirmed);
  const isStatisticsOpen = useAppSelector(
    (state) => state.game.isStatisticsOpen
  );
  const dispatch = useAppDispatch();
  const translate = useTranslate();

  const changeBetAmount = (action: AutoCashOutAction, amount?: number) => {
    let newValue = +bet;

    if (action === "decrease" && newValue - 1 >= 1) {
      newValue -= 1;
    }

    if (!amount && action === "increase" && newValue + 1 <= 100) {
      newValue += 1;
    }

    if (amount) {
      newValue = newValue + amount > 100 ? 100 : newValue + amount;
    }

    dispatch(changeBet(String(newValue)));
  };

  const handleChange = (value: string) => {
    if ((+value && +value <= 100) || value === "") {
      dispatch(changeBet(value));
    }
  };

  const handleBlur = (value: string) => {
    if (value === "") {
      dispatch(changeBet("1"));
    }
  };

  const handleMultiplication = () => {
    const value = +bet * 2 > 100 ? 100 : +bet * 2;

    dispatch(changeBet(String(value)));
  };

  const handleBetConfirm = () => {
    if (balance! < +bet && !betConfirmed) {
      dispatch(toggleBalanceModal(true));

      return;
    }

    if (betConfirmed) {
      dispatch(confirmBet(false));

      SocketIO.cancelBet();
    }

    if (!betConfirmed) {
      dispatch(confirmBet(true));

      SocketIO.betConfirm();
    }
  };

  const handleToggleStatistics = () => {
    dispatch(toggleStatistics(!isStatisticsOpen));
  };

  return (
    <StyledBetControl>
      <BetWrapper>
        <span>{translate("BET")}:</span>

        <ArrowControls
          value={bet}
          handleDecrease={() => changeBetAmount("decrease")}
          handleIncrease={() => changeBetAmount("increase")}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />
      </BetWrapper>

      <ButtonWrapper>
        {buttonProps.map((prop, index) => (
          <Button
            key={index}
            size="mobile"
            fullWidth
            handleClick={() => changeBetAmount("increase", prop)}
            variant="transparent"
            rightIcon={<PlusIcon cursor="pointer" />}
          >
            {prop} $
          </Button>
        ))}
      </ButtonWrapper>

      <Actions>
        <ActionsWrapper>
          <IconHolder variant="mobile" width="30px" height="30px">
            <DoubleIcon width="15" onClick={handleMultiplication} />
          </IconHolder>

          {/* <IconHolder variant="mobile" width="30px" height="30px">
            <SwapIcon />
          </IconHolder> */}

          <IconHolder
            variant="mobile"
            width="30px"
            height="30px"
            onClick={handleToggleStatistics}
          >
            <DiagramIcon />
          </IconHolder>
        </ActionsWrapper>

        {gameStatus !== "pending" && betConfirmed ? (
          <ManualCashOut />
        ) : (
          <Button
            handleClick={handleBetConfirm}
            fullWidth
            disabled={gameStatus !== "pending"}
            size="mobile"
            leftIcon={betConfirmed ? <CancelIcon /> : <CheckIcon />}
            variant={betConfirmed ? "warning" : "success"}
          >
            {translate(betConfirmed ? "CANCEL" : "CONFIRM_BET")}
          </Button>
        )}
      </Actions>
    </StyledBetControl>
  );
};

export default MobileBetControl;
