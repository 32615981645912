export { default as BetControl } from "./BetControl";
export { default as AutoControl } from "./AutoControl";
export { default as HistoryControl } from "./HistoryControl";
export { default as Footer } from "./Footer";
export { default as IconHolder } from "./IconHolder";
export { default as Header } from "./Header";
export { default as ArrowControls } from "./ArrowControls";
export { default as Game } from "./Game";
export { default as ManualCashOut } from "./ManualCashOut";
export { default as MobileGamePad } from "./MobileGamePad";
export { default as ModalContainer } from "./ModalContainer";
