import { memo, useEffect, useRef } from "react";
import { animationInit } from "../canvasAnimation";
import { useAppSelector } from "../redux/hooks";

const Canvas = memo(() => {
  const gameStatus = useAppSelector((state) => state.game.gameStatus);
  const canvas = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    if (canvas.current) {
      animationInit();
    }
  }, [canvas]);

  return (
    <canvas
      style={{
        position: "absolute",
        background: gameStatus === "finish" ? "rgba(94, 11, 11, 0.9)" : "none",
        transition: "background 1s linear",
      }}
      ref={canvas}
      width={document.body.clientWidth}
      height={document.body.clientHeight}
      id="canvas"
    />
  );
});

export default Canvas;
