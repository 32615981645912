import {
  AutoCashOutItem,
  AutoCashOutWrapper,
  PrizeAmount,
  StyledView,
  Rocket,
  WinPlace,
  WinTitle,
} from "./styles";
import { useAppSelector } from "../../../redux/hooks";
import { StyledPlusIcon } from "../../AutoControl/styles";
import rocket from "../../../assets/rocket.png";

const PrizeView = () => {
  const { isTakenAutoCashOut, isTakenHalfAutoCashOut } = useAppSelector(
    (state) => state.game.manualCashOut
  );

  const autoCashOutPrize: number = isTakenHalfAutoCashOut
    ? Number(isTakenAutoCashOut) / 2
    : Number(isTakenAutoCashOut);

  return (
    <StyledView fullHeight>
      <WinPlace>
        <Rocket src={rocket} alt="rocket" />

        <WinTitle>ВЫИГРЫШ</WinTitle>

        <AutoCashOutWrapper>
          {isTakenAutoCashOut && (
            <AutoCashOutItem>
              <span>{autoCashOutPrize.toFixed(2)}$</span>

              <StyledPlusIcon active="#76CC84" />

              <span>Auto Cashout</span>
            </AutoCashOutItem>
          )}

          {isTakenHalfAutoCashOut && (
            <AutoCashOutItem>
              <span>{Number(isTakenHalfAutoCashOut).toFixed(2)}$</span>

              <StyledPlusIcon active="#76CC84" />

              <span>50% auto Cashout</span>
            </AutoCashOutItem>
          )}
        </AutoCashOutWrapper>

        <PrizeAmount>
          {Number(autoCashOutPrize + Number(isTakenHalfAutoCashOut)).toFixed(2)}
          $
        </PrizeAmount>
      </WinPlace>
    </StyledView>
  );
};

export default PrizeView;
