import { StyledMobileGamePad } from "./styles";
import MobileBetControl from "./MobileBetControl";
import MobileArrowControl from "./MobileArrowControl";
import HistoryControl from "../HistoryControl";
import Footer from "../Footer";
import { useAppSelector } from "../../redux/hooks";
import Statistics from "../Statistics";

const MobileGamePad = () => {
  const isStatisticsOpen = useAppSelector(
    (state) => state.game.isStatisticsOpen
  );
  return (
    <StyledMobileGamePad>
      {isStatisticsOpen && <Statistics />}

      <MobileBetControl />

      <MobileArrowControl />

      <HistoryControl />

      <Footer />
    </StyledMobileGamePad>
  );
};

export default MobileGamePad;
