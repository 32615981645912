import styled from "styled-components";
import infoBackground from "../../assets/infoBackground.png";
import { Tooltip } from "react-bootstrap";

export const StyledHeader = styled.div({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  padding: "25px 25px 0px",
  zIndex: 1,

  "@media screen and (max-width: 950px)": {
    justifyContent: "center",
    gap: "20px",
  },
});

export const UsersInfo = styled.div({
  display: "flex",
  alignItems: "center",
  background: `url(${infoBackground})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  borderRadius: "14px",
  padding: "17px 15px",
  color: "white",

  "& div": {
    borderRight: "1px solid #3D546F",
    padding: "0px 8px",

    "&:last-child": {
      borderRight: "none",
    },
  },

  "& .balance": {
    display: "none",
  },

  "@media screen and (max-width: 950px)": {
    "& .mobileSide": {
      display: "none",
    },

    "& .balance": {
      display: "inline-block",
    },
  },
});

export const ActionsWrapper = styled.div({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "5px",
});

export const StyledTooltip = styled(Tooltip)({
  zIndex: 2,
  background: "rgba(0,0,0,0.8)",
  padding: "5px",
  color: "white",
  borderRadius: "5px",
  margin: "5px 0px 0px",
});
