import { ReactElement, ReactNode } from "react";
import { StyledButton } from "./styles";

type ButtonProps = {
  size?: "desktop" | "tab" | "mobile";
  variant?:
    | "primary"
    | "success"
    | "transparent"
    | "warning"
    | "fullWidthSuccess";
  leftIcon?: ReactElement;
  rightIcon?: ReactElement;
  children: ReactNode;
  fullWidth?: boolean;
  width?: string;
  disabled?: boolean;
  handleClick?: () => void;
};

const Button: React.FC<ButtonProps> = ({
  size = "desktop",
  leftIcon,
  rightIcon,
  variant = "primary",
  children,
  fullWidth = false,
  disabled = false,
  handleClick = undefined,
}) => (
  <StyledButton
    size={size}
    onClick={handleClick}
    disabled={disabled}
    variant={variant}
    fullWidth={fullWidth}
  >
    {leftIcon}

    {children}

    {rightIcon}
  </StyledButton>
);

export default Button;
