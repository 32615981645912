import styled from "styled-components";
import { ReactComponent as PlusIcon } from "../../assets/plusIcon.svg";

export const StyledAutoControl = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: "10px",
});

export const Control = styled.div({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  whiteSpace: "nowrap",
  gap: "13px",
  color: "white",
  paddingLeft: "10px",

  p: {
    fontSize: "16px",

    "@media screen and (max-width: 1128px)": {
      fontSize: "12px",
    },
  },
});

export const StyledPlusIcon = styled(PlusIcon)<{ active?: string }>(
  ({ active }) => ({
    cursor: "pointer",
    path: {
      fill: active || "white",
    },
  })
);
