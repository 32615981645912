import { useAppSelector } from "../../../redux/hooks";
import { FooterOpacity, FooterStones } from "./styles";

const StonesFooter = () => {
  const gameStatus = useAppSelector((state) => state.game.gameStatus);

  return (
    <FooterStones
      gameStatus={gameStatus}
      status={gameStatus === "finish" ? "end" : "start"}
    >
      <FooterOpacity />
    </FooterStones>
  );
};

export default StonesFooter;
