import { RocketMan, StyledView, Timer } from "./styles";
import defaultPending from "../../../assets/character/defaultPending.gif";
import exitTheRocket from "../../../assets/character/exitTheRocket.gif";
import { useAppSelector } from "../../../redux/hooks";
import { useEffect, useState } from "react";
import rocketTail from "../../../assets/character/strokesEnd.gif";
import { useTranslate } from "../../../utils/hooks";

const FinishView = () => {
  const finalRate = useAppSelector((state) => state.game.finalRate);
  const [status, setStatus] = useState(true);
  const [isTailVisible, setTailVisibility] = useState(true);
  const translate = useTranslate();

  useEffect(() => {
    setTimeout(() => {
      setStatus(false);
    }, 2800);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setTailVisibility(false);
    }, 800);
  }, []);

  return (
    <StyledView fullHeight>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "43px",
        }}
      >
        <Timer>{translate("CRASHED")}</Timer>
        <Timer>
          {translate("FINAL_RATE")}: {finalRate?.toFixed(2)}
        </Timer>
      </div>

      <RocketMan>
        {status ? (
          <>
            {isTailVisible && (
              <img
                style={{
                  position: "absolute",
                  left: -280,
                  top: 195,
                  width: "100%",
                }}
                src={rocketTail}
                alt="rocketTail"
              />
            )}

            <img
              style={{
                width: "440px",
                height: "285px",
                marginRight: "-210px",
              }}
              src={exitTheRocket}
              alt="astronaut"
            />
          </>
        ) : (
          <img width="100px" src={defaultPending} alt="astronaut" />
        )}
      </RocketMan>
    </StyledView>
  );
};

export default FinishView;
