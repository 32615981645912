import styled from "styled-components";

export const StyledFooter = styled.div({
  display: "flex",
  width: "100%",
  overflowY: "scroll",
  gap: "5px",

  "@media screen and (max-width: 950px)": {
    maxWidth: "350px",
    overflow: "scroll",
  },
});

export const FooterItem = styled.div<{
  background: string;
}>(({ background }) => ({
  width: "100px",
  height: "35px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "11px 20px",
  borderRadius: "14px",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  background: `url(${background})`,
  color: "white",
  fontSize: "18px",

  "&:first-child": {
    marginLeft: "0px",
    boxShadow: "none",
  },
}));
