import { useAppSelector } from "../../redux/hooks";
import BalanceModal from "./BalanceModal";
import FAQModal from "./FAQModal";

const ModalContainer = () => {
  const isBalanceModalOpen = useAppSelector(
    (state) => state.game.isBalanceModalOpen
  );
  const isFAQModalOpen = useAppSelector((state) => state.game.isFAQOpen);

  return (
    <>
      {isBalanceModalOpen && <BalanceModal />}
      {isFAQModalOpen && <FAQModal />}
    </>
  );
};

export default ModalContainer;
