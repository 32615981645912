import styled from "styled-components";
import gameDesktopPlate from "./assets/gamePlate.png";
import background from "./assets/background.png";

export const StyledApp = styled.div({
  width: "100vw",
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  background: `url(${background})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  overflow: "hidden",

  "@media screen and (max-width: 950px)": {
    justifyContent: "flex-start",
    overflow: "scroll",
  },
});

export const ControlsWrapper = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "1128px",
  background: `url(${gameDesktopPlate})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "top",
  padding: "20px 40px 10px",
  zIndex: 3,
  position: "relative",

  "@media screen and (max-width: 1128px)": {
    width: "950px",
    padding: "20px 25px 10px",
  },

  "@media screen and (max-width: 950px)": {
    display: "none",
  },
});

export const Controls = styled.div({
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr)",
  width: "100%",
  gap: "30px",
  marginBottom: "5px",
  alignItems: "flex-start",

  "@media screen and (max-width: 1128px)": {
    gap: "15px",
  },
});

export const StyledVideo = styled.video({
  position: "fixed",
  width: "100%",
  minWidth: "700px",
  height: "100%",
  objectFit: "cover",
  top: 0,
  left: 0,
  zIndex: 0,
});
