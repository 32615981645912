export const TOPICS_EN = {
  INTRODUCTION: [
    'Space Game is an online multiplayer game of "Crash" type. It consists of a multiplier which grows from 1.00x up to a random, predetermined value, when it crashes. Your objective is to cash out before the Crash occurs in order to win your bet multiplied by the current multiplier.',
    "Please, kindly note that Space Game is NOT a game of skill. Your choice has no impact on the game other than deciding when to cash out. Our cute space character will join you in your quest.",
  ],
  HOW_TO_PLAY: [
    'To start playing, select a bet amount and confirm your bet by clicking the "CONFIRM BET" button.',
    'You are allowed to place or modify your bet for the upcoming Round while the messages "NEXT GAME IN .." or "WAIT FOR NEXT GAME" are displayed on the screen, until the message "BETS CLOSED" is displayed.',
    "The total amount of your bet is displayed in the Bet and Total bet fields. When betting time ends, the multiplier is displayed with the initial value of 1.00x. The multiplier then starts to grow, slower at start but increasingly faster as it goes higher.",
    "While it increases, you have the possibility to cash out all at once, or half first and half later.",
    "The later you cash out, the bigger your win, but make sure you do this before the Crash happens. When the Crash occurs, the Game round ends and any amount not cashed out by then is lost.",
    "If the Crash occurs at 1.00x, then all bets placed in that Round are automatically lost. Multiplier value of 5000x is automatically paid out upon being hit towards all the bets remaining in the game.",
    'If you manage to cash out before the Crash, you win the slice of the bet that you decided to cash out multiplied by the current multiplier. Your win amount will be shown in the winning notification. Please, check thoroughly sections "Cashing out" and "Auto Cashout and 50% Auto Cashout" to learn more about the process of claiming your wins.',
    "Once the Game round is finished, you can start placing bets on the upcoming Round.",
  ],
  MULTIPLIER: [
    "The multiplier starts always at 1.00x and goes up to a maximum of 5000x according to the predetermined Crash multiplier value.",
    "Note: Depending on whichever of the following two is reached first, the maximum possible payout in this game is 4,999:1 (5000x) or currency equivalent €500,000 and the game continues after the maximum payout is reached.",
  ],
  PLACING_BET: [
    "When placing a bet, please make sure your bet is placed on time and successfully validated. Minimum and maximum stakes allowed in this game are €1 - €100. Messages in the game inform you of the game status, the remaining time for placing bets and the status of your bets.",
    '"WAIT FOR NEXT GAME" is displayed when you join the game after the betting time ended for the current Round, or after you cash out fully for the current Round. While watching the current Round as a spectator you can start to prepare your bet for the upcoming Round.',
    '"NEXT GAME IN ..s" is displayed when bets are open for the current Round. The timer goes down each second.',
    '“BETS CLOSED" signals the end of betting time and all betting tools will become disabled.',
    'Your bet is then processed and you receive a "BET ACCEPTED" message confirming that your bet has been accepted, or "BET REJECTED". For more information on the latter, check the "Game Inconveniences" section of this guide.',
    'A "LOW BALANCE" message means that your balance is too low to cover the minimum bet amount allowed in the game. You need to top up your balance to be able to place bets.',
    'A "GAME CANCELED" message is displayed if the Game round is canceled due to unforeseen circumstances and immediate resolution is not possible. For more information on this, please refer to the "Game Inconveniences" section of this guide.',
    "The Quick bets option allows you to top up the value of your current bet faster. Four Quick bet tiles are available and by clicking on them, the amount they display is added to your total bet. Only Quick bets that can be used with your current Balance are enabled.",
    "Every click on the Double button doubles your current bet.",
    "A click on the Undo button removes the most recent change you made to your bet in the current Round. Repeatedly clicking the undo button removes changes one by one, with the most recent change removed first.",
    "All the options above are available only during the betting time. Once the bets are closed, you are not able to undo or change your bets.",
    "The Total Bet indicator shows the current total of all bets placed on the current Game round. It is always displayed at the bottom of the game window.",
    "The Wallet button on the left-side panel opens the window with your available funds. Please make sure that your balance is sufficient to cover your bets before you start playing. If your balance is not updated after placing bets or after you see a winning message, please try refreshing your game window in the browser or click the F5 key on your keyboard. At this window you can also find requests for Deposit and Withdrawal.",
    "The Wallet History button below the Wallet button on the left-side panel opens the window for deposits and withdrawals. On mobile devices the Cashier button is located inside the game Menu.",
    "At the Personal Area below the Transaction History you can find your Game ID Number that shows the unique identifier of any game, your Currency information, Personal Information, Identity Verification. Here you can also check and change your password.",
    "If you have any questions and wish to contact Customer Support, you should always note down (or take a screenshot of) the Game ID Number.",
  ],
  CHASHING_OUT: [
    'If your bet is accepted, then you can participate in the current Round and you are able to cash out. For this you have the option to "CASHOUT" fully, or to "CASHOUT 50%". Space Game is a realtime game, for which reason actions with "CASHOUT" and "CASHOUT 50%" buttons are subject to delays depending on your Internet connection, usually around 100-200ms.',
    'When you click either "CASHOUT" or "CASHOUT 50%", you trigger a payout request, which is further settled at the value of an ACTUAL MULTIPLIER by the time it is processed by the game server, providing there is no Crash.',
    '"AUTO CASHOUT" and "50% AUTO CASHOUT" described in the next section "Auto Cashout and 50% Auto Cashout" work quite opposite, paying out instantly at the defined multiplier value including Crash multiplier.',
    '"CASHOUT" means you will take the win for all your bet that is still in the game. The current possible win amount is displayed on the button and changes as the multiplier goes higher. If you click on this button before the Crash, you will trigger confirmation process from the game server and your final winning amount will be settled according to the actual multiplier by the time your request was validated.',
    '"CASHOUT 50%" means you can secure the win for half of your bet and can continue playing with the remaining half to cash it out later at a higher multiplier. If you click on this button before the Crash, you will trigger confirmation process from the game server and your final winning amount will be settled according to the actual multiplier by the time your request was validated. The bet field then updates to the new value. To cash out the second half you need to click the "CASHOUT" button before the Crash.',
    'Auto Cashout and 50% Auto Cashout You activate "AUTO CASHOUT" and/or "50% AUTO CASHOUT" by switching them ON during the betting time of the game. The minimum value to set is 1.01x, the maximum is 4999.99x, because there is no Crash at 5000.00x as it results in an auto payout towards all bets remaining in the game. NOTE: Crash multiplier is only payable when incorporated into "AUTO CASHOUT" or "50% AUTO CASHOUT".',
    'When both auto cashouts turned on, they have the following dependency: "50% AUTO CASHOUT" must be less than "AUTO CASHOUT", so it changes accordingly when "AUTO CASHOUT" is adjusted and disables when "AUTO CASHOUT" equals to 1.01x.',
    'After your bets are accepted, if these options are active, then the payout is processed based on the game result and "AUTO CASHOUT" / "50% AUTO CASHOUT" predefined values.',
    "Once the Round starts you can no longer change these settings, but if you change your mind during the game, you can still use the CASHOUT / CASHOUT 50% buttons to manually cash out at smaller values.",
    "When you play on mobile, Auto Cashout turns to a scaled down view after your bet is accepted.",
  ],
  AUTOPLAY: [
    "The Autoplay feature allows you to automatically repeat your bet for a selected number of game rounds. To activate the Autoplay feature, place your bet, set the Auto Cashout if you wish and then click the Autoplay button.",
    "This opens the Autoplay window where you find all the available settings to start the Autoplay feature. Depending on your casino jurisdiction, some configurations may be mandatory.",
    "While the Autoplay window is open, Autoplay may be started in the Game round when it was initiated or in any of the subsequent Game rounds. After pressing the Start button, the Autoplay feature begins. The remaining number of Autoplay rounds are indicated on the Modify Autoplay button.",
    "While Autoplay is running, you can no longer change your bet or Auto cashout settings. You need to stop Autoplay to modify any of these.",
    "To stop the Autoplay feature, open the Autoplay window and press the Stop button. The Autoplay feature is stopped automatically in the following cases:",
    "1. The selected number of Autoplay rounds reaches 0. You will be notified with an on-screen message.",
    "2. Your balance is too low to continue Autoplay.",
    "3. One or more of the Stop Autoplay triggers occur (if applicable): balance decreases, balance increases, or single win exceeds selected value.",
  ],
  RETURN_TO_PLAYER: ["Space Game theoretical RTP: 95.50%. "],
  LAST_RESULTS: [
    "The Crash values from the most recent rounds are displayed at the bottom of the screen.",
    "Each Round featuring in Last results has three general states: Countdown (betting time), In Play (from Bets Closed until the end of Game round) Specifically coloured Result displaying the Crash multiplier",
  ],
  LEADERBOARD: [
    "The Leaderboard is a dynamically updated list of players who placed bets during the current Game round, their bet amounts, cashed sums, relevant multipliers and winning amounts. Essentially informative in nature.",
    "CASHOUT/AUTO CASHOUT and CASHOUT 50%/50% AUTO CASHOUT operations go with relevant indicators. Half-filled indicators stand for 50% cashouts, and filled indicators stand for full cashouts.",
    "The summary of the leaderboard shows the total number of active players for the current Game round, the total number of players who cashed out and the total amount of their wins in EUR or currency equivalent. Essentially informative in nature.",
  ],
  STATISTICS: [
    "Open the Statistics by click on the Statistics button.",
    "The LAST RESULTS panel show the values of the most recent Crash multipliers. You can choose to see up to 500 last results by changing the number of rounds from the stepper.",
    "By clicking a result tile, you can see more details about that result: Timestamp of the Round; Hash string: this is a hash sent before the Round start. You can use it to verify that the result of the Round is fair; Result string: a string containing the result in it. This is sent at the end of the Round. By hashing this string with SHA256 you will obtain the hash string above, confirming that the Crash result has not been altered during the Round.",
    "The CHARTS panel shows the distribution of results per ranges. You can change the number of rounds from which the charts are generated by using the stepper.",
  ],
  NAVIGATION_CONTROLS: [
    "The Lobby (All Games) button (if applicable) can be clicked at any time from any game. It allows you to easily change game, select any other live game or simply leave the game. You will not be removed from your current game until you have selected the new game you wish to join, so the Lobby can be used to browse other games while remaining at the current one. Once on the lobby page, please click the Return to Game button in order to exit the lobby and return to your open game.",
    "Clicking the Chat button opens the chat window so you can send messages to other players.",
    "If you need to speak with our Live Support team, please select the corresponding chat room by clicking the Support button and you will be assisted in a private chat. Chat messages are monitored by dedicated personnel at all times. Any chat messages may be deleted, and your chat feature may be blocked if you use inappropriate or rude language with other players in the game or flood the chat with unnecessary messages.",
    'Clicking the Volume Control button opens a slider which lets you change the sound volume in your game. Clicking the Volume control button mutes the game - it then turns into a "Muted" icon. By default, the game is opened with muted audio. Please click the Muted button to unmute the game. The icon then turns into the Volume Control button.',
    "Clicking the History button opens a window showing details of your activity in our Casino. By default, it is opened on your history for Today. You can also see your history from previous days by selecting the Older tab. Simply select a Game round from the list to see details of it, including all your bets. The details of your Game round history are shown in the History window and are split into 2 parts: Game round information and bets information.",
    "Clicking the Game Help button opens the Help window you are in right now. Clicking the Settings button opens a Settings window allowing you to set your desired preferences.",
    "In the General tab the following options are available:",
    'Animation on/off. Manage game effects. Language change the interface language. By clicking Animation button, you can change the mode to No Animation mode with most elements being static. On mobile devices this functionality is in the Settings inside the Menu. Clicking the Full Screen button launches Full Screen mode and the game and/or Lobby will fill the entire screen. To exit this mode, either click the button again or press the "Esc" key on your keyboard.',
  ],
  GAME_INCONVENIENCES: [
    "You are personally responsible for the correct placement of your bets. In the event of any technical malfunction, the Game will be canceled. All valid cashouts will stand and all uncashed money will be refunded.",
    "In extreme cases, including but not limited to system and/or software damage, all bets on canceled Round will be subject to refund.",
    'If "CASHOUT"/"AUTO CASHOUT" or "CASHOUT 50%"/"50% AUTO CASHOUT" action cannot be processed or for some reason it was declined by game server, you will receive notification "CASHOUT FAILED".',
    "A bet may be rejected by the system after the betting time is finished due to not being placed in time, not meeting the minimum or maximum requirements, or to other transaction issues.",
    "If the game ends with what you consider to be a wrong result, or in the event of any other irregularities, please contact Casino Customer Support providing the Game ID number.",
  ],
  DISCONNECTION_POLICY: [
    "Pragmatic Play Casino services are provided over the Internet, from which you may be disconnected at times. This poses potential problems to the game flow and your user experience.",
    "In order to minimize the impact of unexpected disconnections, when you lose connectivity to the game server, a reconnect message will be displayed on the screen.",
    "As soon as you get disconnected, the following error handling rules apply:",
    'Your bet will be subject to Cancellation if disconnection detected before "BETS CLOSED";',
    'Your bet will be subject to Refund in case your bet has been confirmed during "BETS CLOSED" but you got disconnected before the game started or current multiplier value is 1x. In both cases 1x should not be the Crash multiplier - otherwise bet will be settled as a loss;',
    "Your bet will be subject to Forced cashout as soon as you get disconnected during the game, providing multiplier value is at least 1.01x and there is no Crash;",
    "Disconnection policy doesn`t apply with Auto Cashout enabled, except for cases with only 50%.",
    "After being disconnected, please check your winnings in the History window. Winnings credited through forced cashout have Cashout (disconnection) bet type and refunds have Refund (disconnection) bet type.",
    "If you have any doubts regarding game outcomes, please contact Live Support within the Game Customer Support, providing them with details of the game in question including the Game ID number.",
  ],
};

export const TOPICS_RU = {
  INTRODUCTION: [
    'Космическая игра — многопользовательская онлайн-игра типа "Краш". Он состоит из множителя, который увеличивается с 1,00x до случайного, заранее определенного значения, когда происходит сбой. Ваша цель — обналичить деньги до того, как произойдет Авария, чтобы выиграть свою ставку, умноженную на текущий множитель.',
    "Пожалуйста, обратите внимание, что космическая игра — это НЕ игра на ловкость. Ваш выбор не влияет на игру, кроме решения, когда обналичить деньги. Наш милый космический персонаж присоединится к вам в ваших поисках.",
  ],
  HOW_TO_PLAY: [
    'Чтобы начать играть, выберите сумму ставки и подтвердите ставку, нажав кнопку "ПОДТВЕРДИТЬ СТАВКУ".',
    'Вы можете сделать или изменить ставку на предстоящий раунд, пока на экране отображаются сообщения "СЛЕДУЮЩАЯ ИГРА..." или "ЖДИТЕ СЛЕДУЮЩУЮ ИГРУ", пока не появится сообщение "СТАВКИ ЗАКРЫТЫ".',
    'Общая сумма вашей ставки отображается в полях "Ставка" и "Общая ставка". Когда время ставок заканчивается, множитель отображается с начальным значением 1,00x. Затем множитель начинает расти, медленнее в начале, но все быстрее по мере продвижения. выше.',
    "Пока он увеличивается, у вас есть возможность обналичить все сразу или половину сначала, а половину позже.",
    "Чем позже вы обналичиваете, тем больше ваш выигрыш, но убедитесь, что вы сделали это до того, как произойдет сбой. Когда произойдет сбой, игровой раунд заканчивается, и любая сумма, не обналиченная к тому времени, будет потеряна.",
    "Если сбой происходит с коэффициентом 1,00x, то все ставки, сделанные в этом раунде, автоматически теряются. Значение множителя 5000x автоматически выплачивается при выигрыше по всем ставкам, оставшимся в игре.",
    'Если вам удастся обналичить деньги до Краха, вы выиграете часть ставки, которую решили обналичить, умноженную на текущий множитель. Сумма вашего выигрыша будет указана в уведомлении о выигрыше. Пожалуйста, внимательно ознакомьтесь с разделами "Выплата наличных" и "Автовыплата и 50% Автовыплата", чтобы узнать больше о процессе получения выигрыша.',
    "После завершения игрового раунда вы можете начать делать ставки на предстоящий раунд.",
  ],
  MULTIPLIER: [
    "Множитель всегда начинается с 1,00x и увеличивается до максимума 5000x в соответствии с заранее установленным значением множителя Crash.",
    "Примечание: в зависимости от того, какое из следующих двух событий будет достигнуто первым, максимально возможная выплата в этой игре составляет 4 999: 1 (5000x) или эквивалент в валюте 500 000 евро, и игра продолжается после достижения максимальной выплаты.",
  ],
  PLACING_BET: [
    "При размещении ставки, пожалуйста, убедитесь, что ваша ставка сделана вовремя и успешно подтверждена. Минимальная и максимальная ставки, разрешенные в этой игре, составляют 1-100 евро. Сообщения в игре информируют вас о статусе игры, оставшемся времени для размещения ставки и статус ваших ставок.",
    '"ОЖИДАТЬ СЛЕДУЮЩУЮ" ИГРУ отображается, когда вы присоединяетесь к игре после окончания времени ставок для текущего раунда или после того, как вы полностью обналичиваете деньги в текущем раунде. Наблюдая за текущим Раундом в качестве зрителя, вы можете начать готовить свою ставку к предстоящему Раунду.',
    '"СЛЕДУЮЩАЯ ИГРА В ..s" отображается, когда ставки открыты для текущего раунда. Таймер срабатывает каждую секунду.',
    '"СТАВКИ ЗАКРЫТЫ" сигнализирует об окончании времени ставок, и все инструменты для ставок будут отключены.',
    'Затем ваша ставка обрабатывается, и вы получаете сообщение "СТАВКА ПРИНЯТА", подтверждающая, что ваша ставка принята, или "СТАВКА ОТКЛОНЕНА". Для получения дополнительной информации о последнем, проверьте раздел "Игровые неудобства" этого руководства.',
    'Сообщение "НИЗКИЙ БАЛАНС" означает, что ваш баланс слишком мал, чтобы покрыть минимальную сумму ставки, разрешенную в игре. Вам необходимо пополнить баланс, чтобы иметь возможность делать ставки.',
    'Сообщение "ИГРА ОТМЕНЕНА" отображается, если игровой раунд отменен из-за непредвиденных обстоятельств и немедленное решение проблемы невозможно. Дополнительные сведения об этом см. в разделе "Игровые неудобства" данного руководства.',
    'Опция "Быстрые ставки" позволяет вам быстрее увеличить сумму вашей текущей ставки. Доступны четыре плитки "Быстрые ставки", и при нажатии на них отображаемая сумма добавляется к вашей общей ставке. Только "Быстрые ставки", которые можно использовать с вашим текущий Баланс включен.',
    'Каждый щелчок по кнопке "Удвоить" удваивает вашу текущую ставку.',
    'Нажатие на кнопку "Отменить" удаляет самое последнее изменение, внесенное вами в вашу ставку в текущем раунде. Многократное нажатие на кнопку "Отменить" удаляет изменения одно за другим, при этом самое последнее изменение удаляется первым.',
    "Все вышеперечисленные опции доступны только во время ставок. После того, как ставки закрыты, вы не можете отменить или изменить свои ставки.",
    'Индикатор "Общая ставка" показывает текущую сумму всех ставок, сделанных в текущем раунде игры. Он всегда отображается внизу игрового окна.',
    "Кнопка Кошелек на левой боковой панели открывает окно с вашими доступными средствами. Пожалуйста, убедитесь, что ваш баланс достаточен для покрытия ваших ставок, прежде чем начать играть. Если ваш баланс не обновляется после размещения ставок или после того, как вы видите выигрышное сообщение, попробуйте обновить окно игры в браузере или нажмите клавишу F5 на клавиатуре. В этом окне вы также можете найти запросы на ввод и вывод средств.",
    'Кнопка "История кошелька" под кнопкой "Кошелек" на левой боковой панели открывает окно ввода и вывода средств. На мобильных устройствах кнопка "Касса" находится внутри игрового меню.',
    "В личном кабинете под историей транзакций вы можете найти свой игровой идентификационный номер, который показывает уникальный идентификатор любой игры, информацию о вашей валюте, личную информацию, подтверждение личности. Здесь вы также можете проверить и изменить свой пароль.",
    "Если у вас есть какие-либо вопросы и вы хотите связаться со службой поддержки, вы всегда должны записать (или сделать снимок экрана) номер игрового идентификатора.",
  ],
  CHASHING_OUT: [
    'Если ваша ставка принята, то вы можете участвовать в текущем раунде и можете обналичить деньги. Для этого у вас есть возможность "CASHOUT" полностью или "CASHOUT 50%". Space Game — это игра в реальном времени, поэтому действия с кнопками "CASHOUT" и "CASHOUT 50%" могут выполняться с задержкой в зависимости от вашего интернет-соединения, обычно около 100-200 мс.',
    'Когда вы нажимаете "CASHOUT" или "CASHOUT 50%", вы инициируете запрос на выплату, который в дальнейшем рассчитывается по значению ФАКТИЧЕСКОГО МНОЖИТЕЛЯ к моменту его обработки игровым сервером, при условии отсутствия сбоя.',
    '"AUTO CASHOUT" и "50% AUTO CASHOUT", описанные в следующем разделе "Auto Cashout и 50% Auto Cashout", работают совершенно противоположно, выплачивая мгновенные выплаты с заданным значением множителя, включая множитель Crash.',
    '"CASHOUT" означает, что вы получите выигрыш по всей вашей ставке, которая все еще находится в игре. Текущая возможная сумма выигрыша отображается на кнопке и меняется по мере увеличения множителя. Если вы нажмете эту кнопку до сбоя, вы запустите процесс подтверждения с игрового сервера, и ваша окончательная сумма выигрыша будет рассчитана в соответствии с фактическим множителем на момент проверки вашего запроса.',
    '"CASHOUT 50%" означает, что вы можете обеспечить выигрыш половины своей ставки и можете продолжить игру с оставшейся половиной, чтобы обналичить ее позже с более высоким множителем. Если вы нажмете эту кнопку до сбоя, вы запустите процесс подтверждения с игрового сервера, и ваша окончательная сумма выигрыша будет рассчитана в соответствии с фактическим множителем на момент проверки вашего запроса. Затем поле ставки обновляется до нового значения. Для обналичивания второй половины необходимо нажать кнопку "CASHOUT" до Crash.',
    'Auto Cashout и 50% Auto Cashout Вы активируете "AUTO CASHOUT" и/или "50% AUTO CASHOUT", включив их во время ставок в игре. Минимальное значение для установки — 1,01x, максимальное — 4999,99x, потому что при 5000,00x нет Crash, поскольку это приводит к автоматической выплате по всем ставкам, оставшимся в игре. ПРИМЕЧАНИЕ. Множитель Crash выплачивается только в том случае, если он включен в "AUTO CASHOUT" или "50% AUTO CASHOUT".',
    'При включении обоих автовыплат они имеют следующую зависимость: "50% АВТО КЭШАУТ" должно быть меньше, чем "АВТО КЭШАУТ", поэтому изменяется соответственно при настройке "АВТО КЭШАУТ" и отключается, когда "АВТО КЭШАУТ" равен 1,01. Икс.',
    'После того, как ваши ставки приняты, если эти опции активны, выплата обрабатывается на основе результата игры и предопределенных значений "АВТО КЭШУТ" / "50% АВТО КЭШУТ".',
    'После начала раунда вы больше не сможете изменить эти настройки, но если вы передумаете во время игры, вы все равно можете использовать кнопки "CASHOUT" / "CASHOUT 50%", чтобы вручную обналичить меньшие суммы.',
    "Когда вы играете на мобильном устройстве, Auto Cashout превращается в уменьшенное изображение после того, как ваша ставка принята.",
  ],
  AUTOPLAY: [
    "Функция автоигры позволяет вам автоматически повторять вашу ставку в течение выбранного количества игровых раундов. Чтобы активировать функцию автоигры, сделайте ставку, установите автоматический вывод средств, если хотите, а затем нажмите кнопку автоигры.",
    "Откроется окно автозапуска, в котором вы найдете все доступные настройки для запуска функции автозапуска. В зависимости от юрисдикции вашего казино некоторые настройки могут быть обязательными.",
    'Пока открыто окно Автоигры, Автоигра может быть запущена в Игровом раунде, когда она была инициирована, или в любом из последующих Игровых раундов. После нажатия кнопки Старт запускается функция Автоигры. Оставшееся количество раундов Автоигры указано на Кнопка "Изменить автовоспроизведение".',
    "Во время работы автоигры вы больше не можете изменить свою ставку или настройки автоматического вывода средств. Вам нужно остановить автоигру, чтобы изменить любой из них.",
    'Чтобы остановить функцию автозапуска, откройте окно автозапуска и нажмите кнопку "Стоп". Функция автозапуска останавливается автоматически в следующих случаях:',
    "1. Выбранное количество раундов автоигры достигает 0. Вы будете уведомлены сообщением на экране.",
    "2. Ваш баланс слишком низок для продолжения автоигры.",
    "3. Срабатывает один или несколько триггеров Stop Autoplay (если применимо): баланс уменьшается, баланс увеличивается, или одиночный выигрыш превышает выбранное значение.",
  ],
  RETURN_TO_PLAYER: ["Теоретическая RTP космической игры: 95,50%."],
  LAST_RESULTS: [
    "Значения Crash из самых последних раундов отображаются внизу экрана.",
    'Каждый раунд, отображаемый в последних результатах, имеет три основных состояния: Обратный отсчет (время ставок), "В игре" (от закрытия ставок до конца игрового раунда)',
  ],
  LEADERBOARD: [
    "Таблица лидеров представляет собой динамически обновляемый список игроков, сделавших ставки в текущем раунде игры, их суммы ставок, суммы выплат, соответствующие множители и суммы выигрышей. По существу носит информативный характер.",
    "Операции CASHOUT/AUTO CASHOUT и CASHOUT 50%/50% AUTO CASHOUT сопровождаются соответствующими индикаторами. Полузаполненные индикаторы означают 50% кэшаутов, а заполненные индикаторы - полные кэшауты.",
    "Сводка таблицы лидеров показывает общее количество активных игроков в текущем игровом раунде, общее количество игроков, которые обналичили деньги, а также общую сумму их выигрышей в евро или эквивалентной валюте. По сути, информативный характер.",
  ],
  STATISTICS: [
    'Откройте статистику, нажав кнопку "Статистика".',
    "Панель ПОСЛЕДНИЕ РЕЗУЛЬТАТЫ показывает значения самых последних множителей сбоев. Вы можете выбрать просмотр до 500 последних результатов, изменив количество раундов с помощью степпера.",
    "Нажав на плитку результата, вы можете увидеть более подробную информацию oб этом результате: Отметка времени раунда; Строка хэша: это хеш, отправленный до начала раунда. Вы можете использовать его, чтобы убедиться, что результат раунда является честным; Результат строка: строка, содержащая результат. Она отправляется в конце раунда. Путем хеширования этой строки с помощью SHA256 вы получите указанную выше хеш-строку, подтверждающую, что результат Crash не был изменен во время раунда.",
    "На панели CHARTS показано распределение результатов по диапазонам. Вы можете изменить количество раундов, из которых создаются диаграммы, с помощью степпера.",
  ],
  NAVIGATION_CONTROLS: [
    'Кнопку "Лобби" (Все игры) (если применимо) можно нажать в любой момент из любой игры. Она позволяет легко сменить игру, выбрать любую другую живую игру или просто выйти из игры. Вы не будете удалены из текущей игры. пока вы не выберете новую игру, к которой хотите присоединиться, чтобы лобби можно было использовать для просмотра других игр, оставаясь в текущей.На странице лобби нажмите кнопку "Вернуться в игру", чтобы выйти из лобби и вернуться к вашей открытой игре.',
    "При нажатии на кнопку чата открывается окно чата, в котором вы можете отправлять сообщения другим игрокам.",
    'Если вам нужно поговорить с нашей командой поддержки в режиме реального времени, выберите соответствующий чат, нажав кнопку "Поддержка", и вам помогут в приватном чате. Сообщения чата постоянно контролируются специальным персоналом. Любые сообщения чата могут быть удалены. , и ваша функция чата может быть заблокирована, если вы используете неуместные или грубые выражения с другими игроками в игре или заполняете чат ненужными сообщениями.',
    'Нажатие кнопки регулировки громкости открывает ползунок, который позволяет изменить громкость звука в игре. Нажатие кнопки регулировки громкости отключает звук в игре — затем он превращается в значок "Приглушено". По умолчанию игра открывается с отключенным звуком. Нажмите кнопку "Приглушено", чтобы включить звук в игре. Затем значок превращается в кнопку регулировки громкости.',
    'Нажатие на кнопку "История" открывает окно с подробной информацией о вашей активности в нашем Казино. По умолчанию оно открывается в вашей истории за "Сегодня". Вы также можете просмотреть свою историю за предыдущие дни, выбрав вкладку "Старые". Просто выберите игровой раунд из список, чтобы просмотреть сведения об этом, включая все ваши ставки. Подробная информация о вашей истории раундов игры отображается в окне "История" и разделена на 2 части: информация о раунде игры и информация о ставках.',
    'Нажатие кнопки "Справка по игре" открывает окно справки, в котором вы сейчас находитесь. Нажатие кнопки "Настройки" открывает окно "Настройки", в котором вы можете установить нужные параметры.',
    'На вкладке "Общие" доступны следующие параметры:',
    'Анимация вкл/выкл. Управление игровыми эффектами. Язык изменить язык интерфейса. Нажав кнопку "Анимация", вы можете изменить режим на "Без анимации", при этом большинство элементов будут статичными. На мобильных устройствах эта функция находится в настройках внутри меню. Нажатие кнопки "Полноэкранный режим" запускает полноэкранный режим, и игра и/или лобби занимают весь экран. Чтобы выйти из этого режима, нажмите кнопку еще раз или нажмите клавишу "Esc" на клавиатуре.',
  ],
  GAME_INCONVENIENCES: [
    "Вы несете личную ответственность за правильность размещения ваших ставок. В случае любого технического сбоя Игра будет отменена. Все действительные выплаты останутся в силе, а все необналиченные деньги будут возвращены.",
    "В крайних случаях, включая, помимо прочего, повреждение системы и/или программного обеспечения, все ставки на отмененный раунд подлежат возврату.",
    'Если "CASHOUT"/"AUTO CASHOUT" или действие "CASHOUT 50%"/"50% AUTO CASHOUT" не может быть обработано или по какой-то причине оно было отклонено игровым сервером, вы получите уведомление "CASHOUT FAILED".',
    "Ставка может быть отклонена системой по истечении времени для ставок из-за несвоевременного размещения, несоблюдения минимальных или максимальных требований или других проблем с транзакцией.",
    "Если игра заканчивается с тем, что вы считаете неправильным результатом, или в случае каких-либо других нарушений, пожалуйста, свяжитесь со службой поддержки казино, предоставив идентификационный номер игры.",
  ],
  DISCONNECTION_POLICY: [
    "Услуги Pragmatic Play Casino предоставляются через Интернет, от которого вы можете время от времени отключаться. Это создает потенциальные проблемы для игрового процесса и вашего пользовательского опыта.",
    "Чтобы свести к минимуму последствия неожиданных отключений, при потере подключения к игровому серверу на экране будет отображаться сообщение о повторном подключении.",
    "Как только вы отключитесь, применяются следующие правила обработки ошибок:",
    'Ваша ставка будет отменена, если разъединение будет обнаружено до сообщения "СТАВКИ ЗАКРЫТЫ";',
    'Ваша ставка подлежит возврату, если ваша ставка была подтверждена во время "СТАВКИ ЗАКРЫТЫ", но вы отключились до начала игры или текущее значение множителя равно 1x. В обоих случаях 1x не должен быть множителем Crash, иначе ставка будет рассчитана как проигрыш;',
    "Ваша ставка будет принудительно обналичена, как только вы отключитесь во время игры, при условии, что значение множителя составляет не менее 1,01x и нет Crash;",
    "Политика отключения не применяется при включенном Auto Cashout, за исключением случаев только с 50%.",
    'После отключения проверьте свой выигрыш в окне "История". Выигрыши, зачисленные в результате принудительного кэшаута, относятся к типу ставки Cashout (отключение), а возмещения относятся к типу ставки Refund (отключение).',
    "Если у вас есть какие-либо сомнения относительно результатов игры, обратитесь в службу поддержки клиентов игры, предоставив им подробную информацию о рассматриваемой игре, включая номер игрового идентификатора.",
  ],
};
