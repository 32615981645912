import { SyntheticEvent } from "react";
import { toggleBalanceModal } from "../../../redux/gameSlice";
import { useAppDispatch } from "../../../redux/hooks";
import { useTranslate } from "../../../utils/hooks";
import { ModalBody, StyledButton, StyledModal } from "./styles";

const BalanceModal = () => {
  const dispatch = useAppDispatch();
  const translate = useTranslate();

  const handleClose = (event: SyntheticEvent) => {
    //@ts-ignore
    if (["background"].includes(event.target.id)) {
      dispatch(toggleBalanceModal(false));
    }
  };

  const redirect = () => {
    window.top!.location.href =
      process.env.REACT_APP_WALLET_REDIRECT_PATH + "/wallet";
  };

  return (
    <StyledModal id="background" onClick={handleClose}>
      <ModalBody>
        {translate("LOW_BALANCE")}

        <StyledButton onClick={redirect}>{translate("DEPOSIT")}</StyledButton>
      </ModalBody>
    </StyledModal>
  );
};

export default BalanceModal;
