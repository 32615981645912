import arrowBackground from "./assets/arrowBackground.png";
import arrowPurpleBackground from "./assets/arrowPurpleBackground.png";
import arrowAquaBackground from "./assets/arrowAquaBackground.png";

export const BACKGROUNDS = [
  arrowBackground,
  arrowPurpleBackground,
  arrowAquaBackground,
  arrowBackground,
  arrowPurpleBackground,
  arrowAquaBackground,
  arrowBackground,
  arrowPurpleBackground,
  arrowAquaBackground,
  arrowBackground,
  arrowPurpleBackground,
  arrowAquaBackground,
  arrowBackground,
  arrowPurpleBackground,
  arrowAquaBackground,
  arrowBackground,
  arrowPurpleBackground,
  arrowAquaBackground,
  arrowBackground,
  arrowPurpleBackground,
];
