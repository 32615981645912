import styled from "styled-components";
import modalBackground from "../../../assets/depositModalBackground.png";
import buttonBackground from "../../../assets/buttonBackground.png";

export const StyledModal = styled.div({
  position: "fixed",
  width: "100vw",
  height: "100vh",
  backdropFilter: "blur(5px)",
  zIndex: 4,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export const ModalBody = styled.div({
  position: "fixed",
  width: "370px",
  height: "331px",
  background: `url(${modalBackground})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  padding: 20,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  fontFamily: "Stolzl",
  color: "white",
  fontSize: "31px",
  textTransform: "uppercase",
});

export const StyledButton = styled.button({
  width: "215px",
  height: "40px",
  background: `url(${buttonBackground})`,
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  border: "none",
  marginTop: "20px",
  fontFamily: "Stolzl",
  fontSize: "16.5px",
  color: "white",
  fontWeight: 600,
  cursor: "pointer",
});
