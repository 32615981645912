import styled from "styled-components";

export const Wrapper = styled.div({
  display: "flex",
  justifyContent: "center",
  width: "100vw",
  height: "100vh",
  position: "fixed",
  zIndex: 4,
});

export const StyledFAQModal = styled.div({
  width: "600px",
  height: "400px",
  display: "flex",
  flexDirection: "column",
});

export const BodyFAQModalHeader = styled.div({
  width: "100%",
  minHeight: "40px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "0px 20px",
  background: "black",
  borderRadius: "20px 20px 0px 0px",
  color: "white",
});

export const FAQModalBody = styled.div({
  width: "100%",
  height: "100%",
  display: "grid",
  gridTemplateColumns: "200px 1fr",
});

export const TopicList = styled.div({
  width: "100%",
  maxHeight: "400px",
  background: "rgba(111,92,92, .9)",
  padding: "10px",
  borderRadius: "0px 0px 0px 20px",
  display: "flex",
  flexDirection: "column",
  overflow: "scroll",
  gap: 10,
});

export const TopicListItem = styled.div<{ isActive: boolean }>(
  ({ isActive }) => ({
    width: "100%",
    background: isActive ? "rgb(130, 141,203)" : "rgba(0,0,0, .5)",
    padding: "10px",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "space-between",
    cursor: "pointer",
    color: isActive ? "black" : "white",
  })
);

export const TopicInfo = styled.div({
  width: "100%",
  height: "100%",
  background: "rgb(101,93,92)",
  padding: "10px",
  borderRadius: "0px 0px 20px 0px",
  color: "white",
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  overflow: "scroll",
});
