import styled from "styled-components";
import circleBackground from "../../assets/circleBackground.png";
import circleActiveBackground from "../../assets/circleActiveBackground.png";
import arrowIconBackground from "../../assets/arrowIconBackground.png";

const backgroundColor = {
  default: `url(${circleActiveBackground})`,
  primary: `url(${circleBackground})`,
  transparent: "transparent",
  mobile: `url(${arrowIconBackground})`,
};

export const StyledIconHolder = styled.div<{
  variant: "default" | "primary" | "transparent" | "mobile";
  width: string;
  height: string;
}>(({ variant, width, height }) => ({
  width,
  height,
  borderRadius: "50%",
  background: backgroundColor[variant],
  backgroundSize: "contain",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",

  "&:hover": {
    background: `url(${circleActiveBackground})`,
    backgroundSize: "contain",

    path: {
      fill: "white",
    },
  },
}));
